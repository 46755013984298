import React from 'react';
import { SearchFeedPageState, swapCurrentFeedPageWithSearchFeedPage } from '../redux/slices/feed';
import { useAppSelector } from '../redux/hooks';
import { useDispatch } from 'react-redux';

interface ISearchStyleBottomSheet {
    filterData: Record<string, string[]>;
    isOpen: boolean;
    onClose: () => void;
    onFilterChange: (filters: Record<string, string[]>) => void;
    initialFilters?: Record<string, string[]>;
}

export const SearchStyleBottomSheet = ({
    filterData,
    isOpen,
    onClose,
    onFilterChange,
}: ISearchStyleBottomSheet) => {
    const feedPage = useAppSelector((state) => state.feed.currentFeedPage) as SearchFeedPageState;
    const dispatch = useDispatch();

    if (!isOpen) return null;

    const handleReset = () => {
        dispatch(swapCurrentFeedPageWithSearchFeedPage({
            ...feedPage,
            filters: { 기장: [], 무드: [] },
        }));
    };

    const handleOnChange = (key: string, value: string) => {
        dispatch(swapCurrentFeedPageWithSearchFeedPage({
            ...feedPage,
            filters: {
                ...feedPage.filters,
                [key]: feedPage.filters[key].includes(value)
                    ? feedPage.filters[key].filter((v) => v !== value)
                    : [...feedPage.filters[key], value],
            },
        }));
    }

    const handleApply = () => {
        onFilterChange(feedPage.filters);
        onClose();
    };

    return (
        <>
            <div className="fixed inset-0 bg-black bg-opacity-50 z-40" onClick={onClose} />
            <div className="fixed bottom-0 w-full max-w-[500px] left-1/2 transform -translate-x-1/2 bg-white rounded-t-[20px] z-50 p-4 max-h-[80%] overflow-y-auto scrollbar-hide">
                <div className="flex justify-between items-center mb-6">
                    <div className="w-1/3" />
                    <h2 className="text-lg font-bold text-center">검색 필터</h2>
                    <button onClick={handleReset} className="text-S_Wave_Orange font-medium w-1/3 text-right">초기화</button>
                </div>
                {Object.entries(filterData).map(([key, items]) => (
                    <div key={key} className="mb-5">
                        <p className="text-sm font-bold mb-2">{key}</p>
                        <div className="flex justify-center flex-wrap gap-2">
                            {items.map((item) => (
                                <button
                                    key={item}
                                    onClick={() => handleOnChange(key, item)}
                                    className={`w-[31%] py-2  rounded-[4px] text-sm font-medium border
                                    ${feedPage && feedPage.filters && feedPage.filters[key].includes(item)
                                            ? ' border-S_Wave_Orange text-S_Wave_Orange'
                                            : ' border-S_Dye_1 text-S_Dye_1'
                                        }`}
                                >
                                    {item}
                                </button>
                            ))}
                        </div>
                    </div>
                ))}
                <button
                    onClick={handleApply}
                    className="w-full py-4 text-center rounded-full bg-S_Dye_Black text-white font-semibold mt-4"
                >
                    적용하기
                </button>
            </div>
        </>
    );
};