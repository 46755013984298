import React from 'react';

interface PostItemProps {
    postId: string;
    imageUrl: string;
    tags?: string[];
    uploaderId: string;
    onClick: (postId: string) => void;
}

export const PostItem: React.FC<PostItemProps> = ({
    postId,
    imageUrl,
    tags,
    uploaderId,
    onClick,
}) => {
    const filterF = (v: string) => {
        if (v === '생머리' || v === '다운펌') return false;
        return true;
    };

    const renderTags = () => {
        if (!tags) return null;
        const filteredTags = tags.filter(filterF);
        if (filteredTags.length === 0) return null;
        if (filteredTags.length === 1) return `#${filteredTags[0]}`;
        return `#${filteredTags[0]} ⋯`;
    };


    return (
        <div
            key={`${postId}`}
            className="mb-4 break-inside-avoid content-loaded"
        >
            <img
                src={imageUrl}
                alt=""
                className="w-full object-cover rounded-lg cursor-pointer"
                style={{ width: '50vw', minWidth: '160px', minHeight: '250px' }}
                onClick={() => onClick(postId)}
                loading={'lazy'}
                onContextMenu={(e) => e.preventDefault()}
            />
            <div className="mt-1 px-1 mb-[26px]">
                <p className="font-semibold text-S_Dye_Black truncate overflow-y-hidden">
                    @
                    {uploaderId.length > 15
                        ? uploaderId.slice(0, 15) + '..'
                        : uploaderId}
                </p>
                <p className="text-S_Dye_1 font-medium break-words">
                    {renderTags()}
                </p>
            </div>
        </div>
    );
};
