import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface User {
    isLoggedIn: boolean;
    id: string | null;
    sonigoId: string | null;
    accessToken: string | null;
}

const INITIAL_STATE: User = {
    isLoggedIn: false,
    id: null,
    sonigoId: null,
    accessToken: null,
};

export const userSlice = createSlice({
    name: "USER",
    initialState: INITIAL_STATE,
    reducers: {
        login: (state, action: PayloadAction<User>) => {
            state.isLoggedIn = true;
            state.id = action.payload.id;
            state.sonigoId = action.payload.sonigoId;
            state.accessToken = action.payload.accessToken;
        },
        logout: (state) => {
            state.isLoggedIn = false;
            state.id = null;
            state.sonigoId = null;
            state.accessToken = null;
        },
    }
});

export const { 
    login, 
    logout 
} = userSlice.actions;

export default userSlice.reducer;




