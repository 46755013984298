import React from "react";

export const ContainIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <g clipPath="url(#clip0_2578_6577)">
                <path d="M3.45181 14.889C2.97813 14.889 2.56944 14.5567 2.47283 14.093L1.1395 7.69297C1.01012 7.07199 1.48416 6.48901 2.11848 6.48901H13.6589C14.2932 6.48901 14.7672 7.07199 14.6378 7.69297L13.3045 14.093C13.2079 14.5567 12.7992 14.889 12.3255 14.889H3.45181Z" stroke="white" strokeWidth="1.5" />
                <path d="M5.78906 9.28906V12.0891" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
                <path d="M9.98828 9.28906V12.0891" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
                <path d="M2.98877 6.48892V5.78892C2.98877 3.08272 5.18258 0.888916 7.88877 0.888916C10.595 0.888916 12.7888 3.08272 12.7888 5.78892V6.48892" stroke="white" strokeWidth="1.5" />
            </g>
            <defs>
                <clipPath id="clip0_2578_6577">
                    <rect width="16" height="16" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};
