import React from 'react'

export const InstagramIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="19" height="20" viewBox="0 0 19 20" fill="none">
            <path d="M14.6256 5.71191C14.4416 5.71191 14.2925 5.56276 14.2925 5.37878C14.2925 5.1948 14.4416 5.04565 14.6256 5.04565" stroke="#ACA39A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M14.6255 5.71191C14.8095 5.71191 14.9586 5.56276 14.9586 5.37878C14.9586 5.1948 14.8095 5.04565 14.6255 5.04565" stroke="#ACA39A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M2 5.42857C2 3.53503 3.53502 2 5.42856 2H14.5715C16.465 2 18 3.53503 18 5.42857V14.5714C18 16.4649 16.465 18 14.5715 18H5.42856C3.53502 18 2 16.4649 2 14.5714V5.42857Z" stroke="#ACA39A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M6.60205 10.0002C6.60205 10.9014 6.96004 11.7656 7.59727 12.4029C8.2345 13.0401 9.09877 13.3981 9.99995 13.3981C10.9011 13.3981 11.7654 13.0401 12.4026 12.4029C13.0399 11.7656 13.3978 10.9014 13.3978 10.0002C13.3978 9.09902 13.0399 8.23475 12.4026 7.59752C11.7654 6.96029 10.9011 6.60229 9.99995 6.60229C9.09877 6.60229 8.2345 6.96029 7.59727 7.59752C6.96004 8.23475 6.60205 9.09902 6.60205 10.0002Z" stroke="#ACA39A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}
