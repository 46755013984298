import React from 'react';

interface LikeBtnProps {
    isActive?: boolean;
    onClick?: () => void;
}

export const LikeBtn: React.FC<LikeBtnProps> = ({
    isActive = false,
    onClick,
}) => {
    return (
        <button className="flex flex-col items-center px-4" onClick={onClick}>
            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                <path d="M8.5047 13.7692L2.13916 8.45447C-1.32038 5.26562 3.76513 -0.856972 8.5047 4.09637C13.2442 -0.856972 18.3068 5.28688 14.8703 8.45447L8.5047 13.7692Z" stroke={isActive ? '#FF671F' : '#ACA39A'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        </button>
    );
};
