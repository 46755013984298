import React from 'react';
import { ampli } from '../ampli';
import { SearchFeedPageState } from '../redux/slices/feed';
import { useAppSelector } from '../redux/hooks';
import { categoryTitleTranslator } from '@features/categoryTranslator';

interface ISearchCategoryBottomSheet {
    title: string
    categories: string[];
    isOpen: boolean;
    onClose: () => void;
    onCategoryChange: (category: string) => void;
}

export const SearchCategoryBottomSheet = ({
    title,
    categories,
    isOpen,
    onClose,
    onCategoryChange,
}: ISearchCategoryBottomSheet) => {
    const feedPage = useAppSelector((state) => state.feed.currentFeedPage) as SearchFeedPageState;

    if (!isOpen) return null;

    const handleSelectData = (d: string) => {
        onCategoryChange(d);
        onClose();
    };

    return (
        <>
            <div
                className="fixed inset-0 bg-black bg-opacity-50 z-40"
                onClick={onClose}
            ></div>
            <div className="fixed bottom-0 w-full max-w-[500px] left-1/2 transform -translate-x-1/2 bg-S_White rounded-t-2xl z-50 p-8 max-h-[80%] overflow-y-auto">
                <h2 className="text-sm font-medium text-center mt-2 mb-3">{title}</h2>
                <div className="flex flex-wrap gap-2 justify-center">
                    {categories.map((d, index) => (
                        <button
                            key={index}
                            onClick={() => {
                                if (d != '전체') {
                                    ampli.검색용상단태그더보기에서태그선택({
                                        searchCategory: categoryTitleTranslator(title),
                                        tag: d
                                    });
                                }
                                handleSelectData(d)
                            }}
                            className={`py-2 px-4 rounded-full text-sm font-medium
                            ${((feedPage && feedPage.category) ? feedPage.category : '전체') === d
                                    ? 'bg-S_WO_20 text-sm font-medium text-S_Wave_Orange shadow-[inset_0px_2px_1px_0px_#FFBE9F] border-[0.5px] border-[#FFCFBE]'
                                    : 'bg-S_White text-sm font-medium text-S_Dye_1 shadow-[inset_0px_-2px_1px_0px_rgba(0,0,0,0.10)] border-[0.5px] border-S_Dye_White'
                                }`}
                        >
                            {d}
                        </button>
                    ))}
                </div>
            </div>
        </>
    );
};