import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { BottomBar } from '../components/BottomBar';
import { useLocation, useNavigate } from 'react-router-dom';
import { SearchTopBar } from '@components/SearchTopBar';
import Lottie from 'lottie-react';
import scissor from '../shared/icons/scissors.json';
import { Toast } from '@components/Toast';
import { InfiniteScroll } from '@shared/utils/InfiniteScroll';
import Masonry from 'react-masonry-css';
import { LoginReferer, useLoginTracking } from '@shared/hooks/usePrevTracking';
import ImageSkeleton from '@components/ImageSkeleton';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../redux/hooks';
import {
    addFeedPage,
    addPostsToCurrentFeed,
    clearFeedPages,
    Feed,
    FeedPage,
    SearchFeedPageState,
    stashCurrentFeedToPastFeeds,
} from '../redux/slices/feed';
import {
    increaseDepth,
    setInitialDepth,
    setSearchOrigin,
} from '../redux/slices/depth';
import { fetchByTags } from '@pages/search.api';
import { UnknownAction } from '@reduxjs/toolkit';
import { setForward } from '../redux/slices/forward';
import { ampli } from '../ampli';
import { categoryTitleTranslator } from '@features/categoryTranslator';

export interface SearchDetailProps {
    title: string;
    filterData: Record<string, string[]>;
    categories: string[];
}

export const SearchDetail: React.FC<SearchDetailProps> = ({
    title,
    categories,
    filterData,
}) => {
    const dispatch = useDispatch();
    const feedPage = useAppSelector(
        (state) => state.feed.currentFeedPage,
    ) as SearchFeedPageState;
    const [loading, setLoading] = useState<boolean>(false);
    const [hasMore, setHasMore] = useState<boolean>(true);
    const [showToast, setShowToast] = useState<boolean>(false);
    const [toastMessage, setToastMessage] = useState<string>('');
    const [selectedCategory, setSelectedCategory] = useState<string>(
        feedPage && feedPage.category ? feedPage.category : '전체',
    );
    const [selectedFilters, setSelectedFilters] = useState<
        Record<string, string[]>
    >(feedPage && feedPage.filters ? feedPage.filters : { 기장: [], 무드: [] });

    const navigate = useNavigate();
    const location = useLocation();
    const { logLoginOrigin } = useLoginTracking();

    // flatTags is flattened array of (filter, category)
    const [flatTags, setFlatTags] = useState<string[]>([]);

    useEffect(() => {
        dispatch(setInitialDepth());
        dispatch(setSearchOrigin());
        initializeFeed();
        logLoginOrigin(LoginReferer.ICON);
        setSelectedFilters(
            feedPage && feedPage.filters
                ? feedPage.filters
                : { 기장: [], 무드: [] },
        );
        setSelectedCategory(
            feedPage && feedPage.category ? feedPage.category : '전체',
        );
    }, []);

    const initializeFeed = async () => {
        if (feedPage && feedPage.pageType === FeedPage.SEARCH) {
            return;
        }

        setLoading(true);
        try {
            setSelectedFilters(
                feedPage && feedPage.filters
                    ? feedPage.filters
                    : { 기장: [], 무드: [] },
            );
            setSelectedCategory(
                feedPage && feedPage.category ? feedPage.category : '전체',
            );
            dispatch(clearFeedPages());
            const { hasMore, feedId, posts, page } = await fetchByTags([]);
            let action: UnknownAction;

            console.log(hasMore);

            if (!hasMore) {
                setHasMore(false);
                action = addFeedPage({
                    pageType: FeedPage.SEARCH,
                    loading: false,
                    feed: null,
                    filters: { 기장: [], 무드: [] },
                    category: '전체',
                } as SearchFeedPageState);
            } else {
                setHasMore(true);
                action = addFeedPage({
                    pageType: FeedPage.SEARCH,
                    loading: false,
                    feed: {
                        id: feedId,
                        posts: posts,
                        lastPage: page,
                    },
                    filters: { 기장: [], 무드: [] },
                    category: '전체',
                } as SearchFeedPageState);
            }

            dispatch(action);
        } finally {
            setLoading(false);
        }
    };

    const loadMore = useCallback(async () => {
        if (!loading && hasMore && feedPage && feedPage.feed) {
            setLoading(true);

            const { hasMore, feedId, posts, page } = await fetchByTags(
                flatTags,
                feedPage.feed.id,
                feedPage.feed.lastPage + 1,
            );

            let action: UnknownAction;
            if (!hasMore) {
                setHasMore(false);
                action = addPostsToCurrentFeed({
                    id: feedId,
                    posts: [],
                    lastPage: page,
                } as Feed);
            } else {
                setHasMore(true);
                action = addPostsToCurrentFeed({
                    id: feedId,
                    posts: posts,
                    lastPage: page,
                } as Feed);
            }

            dispatch(action);
            setLoading(false);
        }
    }, [loading, hasMore, feedPage, selectedCategory, selectedFilters]);

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const toast = params.get('toast');
        if (toast) {
            setToastMessage(toast);
            setShowToast(true);
            navigate('/', { replace: true });
        }
    }, [location, navigate]);

    const handleCloseToast = () => {
        setShowToast(false);
    };

    const breakpointColumnsObj = useMemo(
        () => ({
            default: 2,
            1100: 2,
            700: 2,
            500: 2,
        }),
        [],
    );

    const handleImgClick = (postId: string) => {
        dispatch(stashCurrentFeedToPastFeeds());

        dispatch(increaseDepth());
        dispatch(setForward());
        navigate(`/detail/${postId}`, { state: { isForward: true } });
    };

    const handleCategoryChange = async (category: string) => {
        setSelectedCategory(category);
        setSelectedFilters({ 기장: [], 무드: [] });
        setLoading(true);

        try {
            dispatch(clearFeedPages());
            const tags = category === '전체' ? [] : [category];
            const { hasMore, feedId, posts, page } = await fetchByTags(tags);
            dispatch(
                addFeedPage({
                    pageType: FeedPage.SEARCH,
                    loading: false,
                    feed: hasMore
                        ? {
                            id: feedId,
                            posts: posts,
                            lastPage: page,
                        }
                        : null,
                    filters: { 기장: [], 무드: [] },
                    category: category,
                } as SearchFeedPageState),
            );
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const handleFilterChange = async (filters: Record<string, string[]>) => {
        console.log(filters);
        dispatch(clearFeedPages());
        const filter = Object.values(filters).flat();

        // 중복 제거
        const uniqueTags = Array.from(new Set([selectedCategory, ...filter]));
        setFlatTags(uniqueTags);

        setSelectedFilters(filters);
        setLoading(true);
        try {
            const { hasMore, feedId, posts, page } =
                await fetchByTags(uniqueTags);
            ampli.검색시도({
                searchCategory: categoryTitleTranslator(title),
                tags: uniqueTags,
            });
            let action: UnknownAction;

            if (!hasMore) {
                setHasMore(false);
                action = addFeedPage({
                    pageType: FeedPage.SEARCH,
                    loading: false,
                    feed: null,
                    filters: filters,
                    category: selectedCategory,
                } as SearchFeedPageState);
            } else {
                setHasMore(true);
                action = addFeedPage({
                    pageType: FeedPage.SEARCH,
                    loading: false,
                    feed: {
                        id: feedId,
                        posts: posts,
                        lastPage: page,
                    },
                    filters: filters,
                    category: selectedCategory,
                } as SearchFeedPageState);
            }

            dispatch(action);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };
    return (
        <>
            <SearchTopBar
                title={title}
                categories={categories}
                filterData={filterData}
                onCategoryChange={handleCategoryChange}
                onFilterChange={handleFilterChange}
                onRefresh={() => handleCategoryChange(selectedCategory)}
                selectedCategory={selectedCategory}
                selectedFilters={selectedFilters}
            />
            <div className={'w-full px-3 pb-[88px]'}>
                <div className="pt-[180px]">
                    {feedPage && feedPage.feed && (
                        <InfiniteScroll
                            loadMore={loadMore}
                            hasMore={hasMore}
                            isLoading={loading}
                        >
                            <Masonry
                                breakpointCols={breakpointColumnsObj}
                                className="my-masonry-grid"
                                columnClassName="my-masonry-grid_column"
                            >
                                {feedPage && feedPage.feed
                                    ? feedPage.feed.posts.map((d) => (
                                        <div
                                            key={d.id}
                                            className="mb-4 break-inside-avoid content-loaded"
                                        >
                                            <img
                                                src={d.imageUrl}
                                                alt=""
                                                className="w-full object-cover rounded-lg cursor-pointer"
                                                style={{ width: '50vw' }}
                                                onClick={() =>
                                                    handleImgClick(d.id)
                                                }
                                            // onLoad={() => handleImgLoad(d.postId)}
                                            />
                                            <div className="mt-1 px-1 mb-[26px]">
                                                <p className="font-semibold text-S_Dye_Black truncate overflow-y-hidden">
                                                    @
                                                    {d.uploaderId.length > 15
                                                        ? d.uploaderId.slice(
                                                            0,
                                                            15,
                                                        ) + '..'
                                                        : d.uploaderId}
                                                </p>
                                                <p className="text-S_Dye_1 font-medium break-words">
                                                    {d.tags.map(
                                                        (tag, index) => (
                                                            <span key={index}>
                                                                #{tag}&nbsp;
                                                            </span>
                                                        ),
                                                    )}
                                                </p>
                                            </div>
                                        </div>
                                    ))
                                    : null}
                            </Masonry>
                        </InfiniteScroll>
                    )}
                </div>
                {loading ? (
                    <div className="col-span-full w-full">
                        <div className="flex justify-center mb-[20px]">
                            <div className="w-12 h-12">
                                <Lottie
                                    animationData={scissor}
                                    loop={true}
                                    autoplay={true}
                                />
                            </div>
                        </div>
                        <div className="flex flex-wrap justify-center gap-2 ">
                            {Array.from({ length: 4 }).map((_, index) => (
                                <ImageSkeleton
                                    key={`skeleton-${index}`}
                                    showScissors={false}
                                    className={
                                        !feedPage
                                            ? 'w-[calc(46vw-8px)] sm:w-[200px] h-[200px] sm:h-[300px] aspect-[3/4] rounded-lg'
                                            : ''
                                    }
                                />
                            ))}
                        </div>
                    </div>
                ) : (
                    <div className="py-10"></div>
                )}
            </div>
            <div className="fixed max-w-[500px] w-full bottom-0 left-1/2 transform -translate-x-1/2">
                <BottomBar activePage={'/search'} />
            </div>
            <div className="sticky w-full left-0 right-0 bottom-24 z-50">
                {showToast && (
                    <Toast
                        message={toastMessage}
                        duration={3000}
                        onClose={handleCloseToast}
                    />
                )}
            </div>
        </>
    );
};
