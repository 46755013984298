import React, { useEffect, useState } from 'react';
import { BottomBar } from '@components/BottomBar';
import { LegoSurpriseImg } from '@shared/imges/LegoSurpriseImg';
import { API } from '../api/API';
import { useNavigate } from 'react-router-dom';
import Lottie from 'lottie-react';
import scissor from '@shared/icons/scissors.json';
import { ampli } from '../ampli';
import { LoginReferer, useLoginTracking } from '@shared/hooks/usePrevTracking';
import { useAppSelector } from '../redux/hooks';
import { useDispatch } from 'react-redux';
import {
    increaseDepth,
    setInitialDepth,
    setSaveOrigin,
} from '../redux/slices/depth';
import { stashCurrentFeedToPastFeeds } from '../redux/slices/feed';

// interface IUserInfo {
//     ownerId: string;
//     ownerSonigoId: string;
//     posts: string[];
// }

interface ISaveImg {
    id: string;
    imageUrl: string;
    savedAt: string;
    uploaderSonigoId: string;
    tags: [];
}

interface ImageSkeletonProps {
    showScissors?: boolean;
    className?: string;
    height?: string;
}

const ImageSkeleton: React.FC<ImageSkeletonProps> = ({
    showScissors = true,
    className = '',
}) => {
    return (
        <div
            className={`aspect-square relative bg-gray-200 animate-pulse rounded-lg ${className} `}
        >
            {showScissors && (
                <div className="absolute inset-0 flex justify-center items-center">
                    <div className="w-12 h-12">
                        <Lottie
                            animationData={scissor}
                            loop={true}
                            autoplay={true}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export const Bookmark = () => {
    const navigate = useNavigate();
    const [savedImg, setSavedImg] = useState<ISaveImg[] | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const { logLoginOrigin } = useLoginTracking();

    const depth = useAppSelector((state) => state.depth.depth);
    const origin = useAppSelector((state) => state.depth.origin);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setInitialDepth());
        dispatch(setSaveOrigin());
        // dispatch(clearFeedPages());
    }, []);

    useEffect(() => {
        const fetch = async () => {
            setLoading(true);
            try {
                const res = await API.bookmark.get();
                console.log(res);
                if (res.data?.result?.posts) {
                    setSavedImg(res.data.result.posts);
                } else {
                    throw new Error('No data');
                }
            } catch (error) {
                console.error('Error fetching bookmarks', error);
                if (!API.isAuthorized()) {
                    logLoginOrigin(LoginReferer.SAVE_TAB);
                    navigate('/login?toast=로그인 후 사용이 가능해요.');
                    return;
                }
            } finally {
                setLoading(false);
            }
        };
        fetch();
    }, []);

    const handleImgClick = (postId: string) => {
        const clickedPost = savedImg.find((post) => post.id === postId);

        ampli.포스트자세히보기썸네일클릭({
            depth: depth,
            origin: origin,
            postId: postId,
            tags: clickedPost?.tags,
        });
        dispatch(stashCurrentFeedToPastFeeds());
        dispatch(increaseDepth());
        navigate(`/detail/${postId}`);
    };

    return (
        <>
            <div className="flex flex-col w-full px-2 h-lvh">
                {/* 상단 바 */}
                <div className="flex justify-start pt-[14px] pb-[26px]">
                    <p className="text-black text-xl font-semibold">저장</p>
                </div>

                {loading ? (
                    <div className="col-span-full w-full">
                        <div className="flex justify-center mb-4">
                            <div className="w-12 h-12">
                                <Lottie
                                    animationData={scissor}
                                    loop={true}
                                    autoplay={true}
                                />
                            </div>
                        </div>
                        <div className="flex flex-wrap justify-center gap-2 ">
                            {Array.from({ length: 8 }).map((_, index) => (
                                <ImageSkeleton
                                    key={`skeleton-${index}`}
                                    showScissors={false}
                                    className={
                                        'w-[calc(46vw-8px)] sm:w-[200px] h-[200px] sm:h-[300px] aspect-[3/4] rounded-lg'
                                    }
                                />
                            ))}
                        </div>
                    </div>
                ) : savedImg && savedImg.length > 0 ? (
              
                    <div
                        style={{
                            columnCount: 2,
                            columnGap: '0.5rem',
                            paddingBottom: '95px',
                        }}
                    >
                        {savedImg &&
                            savedImg.map((d) => (
                                <div key={d.id} className="break-inside-avoid content-loaded">
                                    <img
                                        src={d.imageUrl}
                                        alt=""
                                        className="w-full object-cover rounded-lg cursor-pointer"
                                        style={{ width: '50vw' }}
                                        onClick={() => handleImgClick(d.id)}
                                    />
                                    <div className="mt-1 mb-[26px]">
                                        <p className="font-semibold text-S_Dye_Black">
                                            @{d.uploaderSonigoId}
                                        </p>
                                        {/* <p className="text-S_Dye_1 font-medium">
                                        {d.tags.map((tag, index) => (
                                            <span key={index}>#{tag}</span>
                                        ))}
                                    </p> */}
                                    </div>
                                </div>
                            ))}
                    </div>
                ) : (
                    <div className="flex-grow">
                        <div className="flex flex-col justify-center items-center h-[85%]">
                            <LegoSurpriseImg />
                            <p className="text-S_Dye_Black font-semibold mt-[31px]">
                                저장된 헤어가 없어요.
                            </p>
                        </div>
                    </div>
                )}
                <BottomBar activePage="bookmark" />
            </div>
        </>
    );
};

