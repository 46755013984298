import { AxiosResponse } from "axios";
import { API, IFeedResponse, IPostDetailResponse } from "../api/API";
import { Post } from "../redux/slices/feed";

export const fetchCurrentPostDetail = async (
    postId: string,
): Promise<{
    postId: string,
    imageUrl: string,
    instagramUrl: string,
    isBookmarked: boolean,
    tags: string[],
    uploaderSonigoId: string,
}> => {
    let res: AxiosResponse<IPostDetailResponse>;

    try {
        if (API.isAuthorized()) {
            res = await API.post.detail(postId);
        } else {
            res = await API.postNoAuth.detail(postId);
        }
        
        const {
            tags,
            hasBeenSaved,
            externalLink,
            contentImageUrl,
            uploaderSonigoId,
        } = res.data.result;

        return {
            postId,
            imageUrl: contentImageUrl,
            instagramUrl: externalLink,
            isBookmarked: hasBeenSaved,
            tags,
            uploaderSonigoId,
        };
    } catch (error) {
        return {
            postId,
            imageUrl: 'https://picsum.photos/id/223/200', // placeholder image
            instagramUrl: 'https://instagram.com/sonnimigungodegiyeyo', // default instagram url to admin
            isBookmarked: false,
            tags: [],
            uploaderSonigoId: 'admin', // default uploader as admin
        }
    }
}

export const fetchByPostIdWithTagsAndPageNumber = async (
    postId: string,
    tags: string[],
    pageNumber: number,
    feedId?: string,
    refresh?: boolean,
): Promise<{
    hasMore: boolean,
    feedId: string | null,
    postId: string,
    posts: Post[],
    tags: string[],
    page: number | null,
}> => {
    try {
        let res: AxiosResponse<IFeedResponse>;

        if (API.isAuthorized()) {
            if (refresh) {
                res = await API.feed.related.refresh(
                    postId,
                    feedId,
                    tags,
                );
            } else if (pageNumber > 1) {
                res = await API.feed.related.next(
                    postId,
                    pageNumber,                         
                    feedId,
                    tags,
                );
            } else {
                res = await API.feed.related.get(
                    postId,
                    tags,
                );
            }
        } else {
            if (refresh) {
                res = await API.feed.relatedNoAuth.refresh(
                    postId,
                    feedId,
                    tags,
                );
            } else if (pageNumber > 1) {
                res = await API.feed.relatedNoAuth.next(
                    postId,
                    pageNumber,
                    feedId,
                    tags,
                );
            } else {
                res = await API.feed.relatedNoAuth.get(
                    postId,
                    tags,
                );
            }
        }

        if (
            res.data.statusCode === 200 && 
            res.data.message === 'success'
        ) {
            const {
                feedId: newFeedId,
                posts,
                page,
            } = res.data.result;

            const parsedPosts = posts.map((post) => {
                return {
                    id: post.postId,
                    uploaderId: post.uploaderSonigoId,
                    imageUrl: post.imageUrl,
                    tags: post.tags,
                } as Post
            });

            return {
                hasMore: posts.length > 0,
                feedId: newFeedId,
                postId,
                posts: parsedPosts,
                tags,
                page,
            };
        } else {
            throw new Error('API response was not successful');
        }
    } catch (error) {
        return {
            hasMore: false,
            feedId: null,
            postId,
            posts: [],
            tags,
            page: pageNumber === 1 ? 1 : pageNumber - 1,
        }
    }
}