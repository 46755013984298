import { AxiosResponse } from "axios";
import { API, IFeedResponse } from "../api/API";
import { Post } from "../redux/slices/feed";

export const filterDuplicatePosts = (prevData, posts) => Array.from(
    new Set(
        [...prevData, ...posts].map((post) => post.postId),
    ),
).map((id) =>
    [...prevData, ...posts].find(
        (post) => post.postId === id,
    ),
) as Post[];

export const fetchByKeywordAndPageNumber = async (
    keyword: string,
    pageNumber: number,
    feedId?: string,
    refresh?: boolean,
): Promise<{
    hasMore: boolean,
    feedId: string | null,
    posts: Post[],
    page: number | null,
}> => {
    try {
        let res: AxiosResponse<IFeedResponse>;

        switch (keyword) {
            case '2024 남자머리':
                if (pageNumber > 1) {
                    if (API.isAuthorized()) {
                        if (refresh) {
                            res = await API.feed.home.refresh();
                        } else {
                            res = await API.feed.home.next(
                                feedId,
                                pageNumber,
                            );
                        }
                    } else {
                        if (refresh) {
                            res = await API.feed.homeNoAuth.refresh(feedId);
                        } else {
                            res = await API.feed.homeNoAuth.next(
                                feedId,
                                pageNumber,
                            );
                        }
                    }
                } else {
                    if (API.isAuthorized()) {
                        if (refresh) {
                            res = await API.feed.home.refresh();
                        } else {
                            res = await API.feed.home.get();
                        }
                    } else {
                        if (refresh) {
                            res = await API.feed.homeNoAuth.refresh(feedId);
                        } else {
                            res = await API.feed.homeNoAuth.get();
                        }
                    }
                }
                break;
            case '샤기컷 붐은 온다':
                if (pageNumber > 1) {
                    res = await API.recommend.샤기컷_붐은_온다.next(
                        feedId,
                        pageNumber,
                    );
                } else if (refresh) {
                    res =
                        await API.recommend.샤기컷_붐은_온다.refresh(
                            feedId,
                        );
                } else {
                    res = await API.recommend.샤기컷_붐은_온다.get();
                }
                break;
            case '장발이 하고싶나?':
                if (pageNumber > 1) {
                    res = await API.recommend.장발이_하고싶나.next(
                        feedId,
                        pageNumber,
                    );
                } else if (refresh) {
                    res =
                        await API.recommend.장발이_하고싶나.refresh(feedId);
                } else {
                    res = await API.recommend.장발이_하고싶나.get();
                }
                break;
            case '헤어도 빈티지':
                if (pageNumber > 1) {
                    res = await API.recommend.헤어도_빈티지.next(
                        feedId,
                        pageNumber,
                    );
                } else if (refresh) {
                    res = await API.recommend.헤어도_빈티지.refresh(feedId);
                } else {
                    res = await API.recommend.헤어도_빈티지.get();
                }
                break;
            case '집돌이 무드':
                if (pageNumber > 1) {
                    res = await API.recommend.집돌이_무드.next(
                        feedId,
                        pageNumber,
                    );
                } else if (refresh) {
                    res = await API.recommend.집돌이_무드.refresh(feedId);
                } else {
                    res = await API.recommend.집돌이_무드.get();
                }
                break;
            case '짧아서 더 멋짐':
                if (pageNumber > 1) {
                    res = await API.recommend.짧아서_더_멋짐.next(
                        feedId,
                        pageNumber,
                    );
                } else if (refresh) {
                    res =
                        await API.recommend.짧아서_더_멋짐.refresh(feedId);
                } else {
                    res = await API.recommend.짧아서_더_멋짐.get();
                }
                break;
            case '미친 개성':
                if (pageNumber > 1) {
                    res = await API.recommend.미친_개성.next(
                        feedId,
                        pageNumber,
                    );
                } else if (refresh) {
                    res = await API.recommend.미친_개성.refresh(feedId);
                } else {
                    res = await API.recommend.미친_개성.get();
                }
                break;
            case '깔끔하게 해주세요':
                if (pageNumber > 1) {
                    res = await API.recommend.깔끔하게_해주세요.next(
                        feedId,
                        pageNumber,
                    );
                } else if (refresh) {
                    res =
                        await API.recommend.깔끔하게_해주세요.refresh(
                            feedId,
                        );
                } else {
                    res = await API.recommend.깔끔하게_해주세요.get();
                }
                break;
            case '뽀글뽀글':
                if (pageNumber > 1) {
                    res = await API.recommend.뽀글뽀글.next(
                        feedId,
                        pageNumber,
                    );
                } else if (refresh) {
                    res = await API.recommend.뽀글뽀글.refresh(feedId);
                } else {
                    res = await API.recommend.뽀글뽀글.get();
                }
                break;
        }
        if (
            res.data.statusCode === 200 && 
            res.data.message === 'success'
        ) {
            const {
                feedId: newFeedId,
                posts,
                page,
            } = res.data.result;

            const parsedPosts = posts.map((post) => {
                return {
                    id: post.postId,
                    uploaderId: post.uploaderSonigoId,
                    imageUrl: post.imageUrl,
                    tags: post.tags,
                } as Post
            });

            return {
                hasMore: posts.length > 0,
                feedId: newFeedId,
                posts: parsedPosts,
                page,
            };
        } else {
            throw new Error('API response was not successful');
        }
    } catch (error) {
        return {
            hasMore: false,
            feedId: null,
            posts: [],
            page: pageNumber === 1 ? 1 : pageNumber - 1,
        }
    }
};