import React from 'react';
import { WhiteXBtn } from './buttons/WhiteXBtn';

interface TooltipProps {
    text: string;
    arrowPosition?: number;
    onClose: (e: React.MouseEvent) => void;
    isVisible: boolean;
}

export const Tooltip: React.FC<TooltipProps> = ({ text, arrowPosition = 65, onClose, isVisible }) => {
    if (!isVisible) return null;

    return (
        <div className="relative mb-3 inline-block min-w-[252px]">
            <div className="rounded-full py-[2px] px-4 bg-[#FF6C2C] flex items-center justify-center relative z-10 whitespace-nowrap">
                <p className="font-medium text-white  text-sm">{text}</p>
                <span className="pl-2" onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    onClose(e);
                }}>
                    <WhiteXBtn />
                </span>
            </div>
            <div
                className={`absolute w-0 h-0 border-l-[8px] border-l-transparent -bottom-2 border-r-[8px] border-r-transparent border-t-[8px] border-t-[#FF6C2C] -translate-x-1/2 z-10`}
                style={{ left: `${arrowPosition}%` }}
            ></div>
        </div>
    );
};