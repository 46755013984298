import React, { useEffect, useRef, useState } from 'react';
import { FilterDropdownIcon } from '@shared/icons/FilterDropdownIcon';
import { PrevIcon } from '@shared/icons/PrevIcon';
import { RefreshIcon } from '@shared/icons/RefreshIcon';
import { useNavigate } from 'react-router-dom';
import { BottomSheet } from './BottomSheet';
import { useModal } from '@shared/hooks/useModal.hook';
import {
    womenModalContainer,
    womenModalLayout,
    womenModalTransiton,
} from '@shared/style/womenModal.styled';
import { LegoSadImg } from '@shared/imges/LegoSadImg';
import { SearchStyleBottomSheet } from './SearchStyleBottomSheet';
import { API } from '../api/API';
import { ampli } from '../ampli';
import { SearchCategoryBottomSheet } from '@components/SearchCategoryBottomSheet';
import { useAppSelector } from '../redux/hooks';
import { clearFeedPages, SearchFeedPageState } from '../redux/slices/feed';
import { useDispatch } from 'react-redux';
import { categoryTitleTranslator } from '@features/categoryTranslator';
import { scrollToTop } from '@shared/utils/scrollToTop';

interface IData {
    data: string[];
}

const Data: IData = {
    data: ['남자', '기장', '무드'],
};

interface SearchDetailTopBarProps {
    title: string;
    categories: string[];
    filterData: Record<string, string[]>;
    onFilterChange: (filters: Record<string, string[]>) => void;
    onCategoryChange: (category: string) => void;
    onRefresh?: () => void;
    selectedCategory: string;
    selectedFilters: Record<string, string[]>;
}

export const SearchTopBar: React.FC<SearchDetailTopBarProps> = ({
    title,
    categories,
    filterData,
    onFilterChange,
    onCategoryChange,
    onRefresh,
    selectedCategory,
    selectedFilters,
}) => {
    const [isBottomSheetOpen, setIsBottomSheetOpen] = useState(false);
    const [isHairstyleBottomSheetOpen, setIsHairstyleBottomSheetOpen] =
        useState(false);
    const [isCutStyleBottomSheetOpen, setIsCutStyleBottomSheetOpen] =
        useState(false);

    const [selectedGender, setSelectedGender] = useState('남자');
    const [isClosing, setIsClosing] = useState(false);

    // 카테고리
    // const [selectedCategory, setSelectedCategory] = useState('전체');
    const [visibleCategories, setVisibleCategories] = useState<string[]>(
        categories.slice(0, 4),
    );
    //
    // // 필터
    // const [selectedFilters, setSelectedFilters] = useState<Record<string, string[]>>({
    //     기장: [],
    //     무드: []
    // });

    const feedPage = useAppSelector(
        (state) => state.feed.currentFeedPage as SearchFeedPageState,
    );

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleOpenBottomSheet = () => setIsBottomSheetOpen(true);
    const handleCloseBottomSheet = () => setIsBottomSheetOpen(false);

    const handleOpenHairstyleBottomSheet = () => {
        {
            ampli.검색용상단태그더보기클릭({
                searchCatagory: categoryTitleTranslator(title),
            });
            setIsHairstyleBottomSheetOpen(true)
        }
    };
    const handleCloseHairstyleBottomSheet = () =>
        setIsHairstyleBottomSheetOpen(false);

    const handleOpenCutStyleBottomSheet = () =>
        setIsCutStyleBottomSheetOpen(true);
    const handleCloseCutStyleBottomSheet = () =>
        setIsCutStyleBottomSheetOpen(false);

    const handlePrevClick = async () => {
        if (!feedPage) return;
        if (!feedPage.feed) return;
        dispatch(clearFeedPages());

        try {
            if (API.isAuthorized()) {
                API.feed.kill(feedPage.feed.id);
            } else {
                API.feed.killNoAuth(feedPage.feed.id);
            }
        } catch (error) {
            console.error(error);
        }

        navigate(-1);
    };

    const womenModalRef = useRef<HTMLDivElement>(null);
    const {
        isModalOpen: womenModalOpen,
        openModal: womenOpenModal,
        closeModal: womenCloseModal,
    } = useModal<null>(null);

    useEffect(() => {
        if (selectedGender === '여자') {
            womenOpenModal();
            setSelectedGender('남자');
        }
    }, [selectedGender]);

    const handleCategoryChange = (
        category: string,
        isFromBottomSheet = false,
    ) => {
        if (isFromBottomSheet) {
            setVisibleCategories([category]);
        }

        onCategoryChange(category);
        scrollToTop();
    };

    const handleFilterChange = (filters: Record<string, string[]>) => {

        onFilterChange(filters);
        scrollToTop();
    };

    const handleRefresh = () => {
        if (onRefresh) onRefresh();
        scrollToTop();
    };

    const handleModalClose = () => {
        setIsClosing(true);
        setTimeout(() => {
            womenCloseModal();
            setIsClosing(false);
        }, 200);
    };

    useEffect(() => {
        console.log('selectedCategory', selectedCategory);
    }, [selectedCategory]);

    useEffect(() => {
        console.log('selectedFilters', selectedFilters);
    }, [selectedFilters]);

    return (
        <div className="fixed max-w-[500px] w-full  top-0 bg-white z-10 max-h-screen overflow-y-auto">
            <div className="sticky top-0 bg-white z-20">
                <div className="flex w-full px-4 py-4 items-center">
                    <button
                        onClick={handlePrevClick}
                        className="absolute left-4"
                    >
                        <PrevIcon />
                    </button>
                    <div className="flex-grow text-center font-semibold text-lg">
                        {title}
                    </div>
                </div>
                <div
                    className={`flex w-full px-4 ${visibleCategories.length === 1 ? 'justify-between px-6' : 'justify-between items-center'}`}
                >
                    <div
                        className={`flex ${visibleCategories.length === 1 ? 'flex-grow' : 'gap-[10px]'}`}
                    >
                        {selectedCategory ? (
                            <>
                                {visibleCategories.map((category, index) => (
                                    <button
                                        key={index}
                                        onClick={() => {
                                            ampli.검색용상단태그선택({
                                                searchCategory: categoryTitleTranslator(title),
                                                tag: category,
                                            });
                                            handleCategoryChange(category);
                                        }}
                                        className={`py-2 px-[14px] rounded-full
                                    ${selectedCategory === category
                                                ? 'bg-S_WO_20 text-sm font-medium text-S_Wave_Orange shadow-[inset_0px_2px_1px_0px_#FFBE9F] border-[0.5px] border-[#FFCFBE]'
                                                : 'bg-S_White text-sm font-medium text-S_Dye_1 shadow-[inset_0px_-2px_1px_0px_rgba(0,0,0,0.10)] border-[0.5px] border-S_Dye_White'
                                            }`}
                                    >
                                        {category}
                                    </button>
                                ))}
                            </>
                        ) : (
                            <>
                                {visibleCategories.map((category, index) => (
                                    <button
                                        key={index}
                                        onClick={() => {
                                            ampli.검색용상단태그선택({
                                                searchCategory: categoryTitleTranslator(title),
                                                tag: category,
                                            });
                                            handleCategoryChange(category);
                                        }}
                                        className={`py-2 px-[14px] rounded-full bg-S_White text-sm font-medium text-S_Dye_1 shadow-[inset_0px_-2px_1px_0px_rgba(0,0,0,0.10)] border-[0.5px] border-S_Dye_White'
                                        `}
                                    >
                                        {category}
                                    </button>
                                ))}
                            </>
                        )}
                    </div>
                    <button
                        className="font-semibold text-sm text-S_Wave_Orange ml-2"
                        onClick={handleOpenHairstyleBottomSheet}
                    >
                        더보기
                    </button>
                </div>
                <div className="w-full h-[2px] bg-S_Dye_White mt-[10px]"></div>
            </div>
            <div className="flex w-full overflow-hidden mb-2 pl-4 mt-[10px]">
                <button className="flex-shrink-0 mr-2" onClick={handleRefresh}>
                    <RefreshIcon />
                </button>
                <div className="flex-1 overflow-hidden">
                    <ul className="flex items-center flex-nowrap whitespace-nowrap gap-2 overflow-x-auto scrollbar-hide">
                        {Data.data.map((d, index) => (
                            <React.Fragment key={d}>
                                {selectedFilters ? (
                                    <li
                                        key={index}
                                        onClick={() => {
                                            if (d === '남자') {
                                                ampli.검색용필터열기({
                                                    filterKind: 'gender',
                                                    searchCatagory: categoryTitleTranslator(title),
                                                });
                                                handleOpenBottomSheet();
                                            } else if (d == '기장') {
                                                ampli.검색용필터열기({
                                                    filterKind: 'length',
                                                    searchCatagory: categoryTitleTranslator(title),
                                                });
                                                handleOpenCutStyleBottomSheet();
                                            } else if (d == '무드') {
                                                ampli.검색용필터열기({
                                                    filterKind: 'mood',
                                                    searchCatagory: categoryTitleTranslator(title),
                                                });
                                                handleOpenCutStyleBottomSheet();
                                            }
                                        }}
                                        className={`flex items-center gap-2 flex-shrink-0 py-2 px-3 font-medium rounded-[4px] cursor-pointer
                                ${Object.keys(selectedFilters)
                                                .flat()
                                                .includes(d) &&
                                                selectedFilters[d].length > 0
                                                ? 'border-[1.2px] border-S_Wave_Orange text-S_Wave_Orange'
                                                : 'border-[0.5px] border-S_Dye_1 text-S_Dye_1'
                                            }`}
                                    >
                                        <span>
                                            {d === '남자'
                                                ? d
                                                : (Object.keys(selectedFilters)
                                                    .flat()
                                                    .includes(d) &&
                                                    selectedFilters[d]
                                                        .length > 0 &&
                                                    `${d} ${selectedFilters[d].length}`) ||
                                                `${d} 전체`}
                                        </span>
                                        <FilterDropdownIcon
                                            color={
                                                Object.keys(selectedFilters)
                                                    .flat()
                                                    .includes(d) &&
                                                    selectedFilters[d].length > 0
                                                    ? '#FFA26B'
                                                    : '#6E6E6E'
                                            }
                                        />
                                    </li>
                                ) : (
                                    <li
                                        key={index}
                                        onClick={() => {
                                            if (d === '남자') {
                                                ampli.검색용필터열기({
                                                    filterKind: 'gender',
                                                    searchCatagory: categoryTitleTranslator(title),
                                                });
                                                handleOpenBottomSheet();
                                            } else if (d == '기장') {
                                                ampli.검색용필터열기({
                                                    filterKind: 'length',
                                                    searchCatagory: categoryTitleTranslator(title),
                                                });
                                                handleOpenCutStyleBottomSheet();
                                            } else if (d == '무드') {
                                                ampli.검색용필터열기({
                                                    filterKind: 'mood',
                                                    searchCatagory: categoryTitleTranslator(title),
                                                });
                                                handleOpenCutStyleBottomSheet();
                                            }
                                        }}
                                        className={`flex items-center gap-2 flex-shrink-0 py-2 px-3 font-medium rounded-[4px] cursor-pointer border-[0.5px] border-S_Dye_1 text-S_Dye_1`}
                                    >
                                        <span>{`${d} 전체`}</span>
                                        <FilterDropdownIcon color={'#6E6E6E'} />
                                    </li>
                                )}
                            </React.Fragment>
                        ))}
                    </ul>
                </div>
            </div>
            <BottomSheet
                isOpen={isBottomSheetOpen}
                onClose={handleCloseBottomSheet}
                setSelectedGender={setSelectedGender}
            />
            <SearchCategoryBottomSheet
                title={title}
                categories={categories}
                isOpen={isHairstyleBottomSheetOpen}
                onClose={handleCloseHairstyleBottomSheet}
                onCategoryChange={(category) => {
                    ampli.검색용상단태그더보기에서태그선택({
                        searchCategory: categoryTitleTranslator(title),
                        tag: category,
                    });
                    handleCategoryChange(category, true)
                }}
            />
            <SearchStyleBottomSheet
                filterData={filterData}
                isOpen={isCutStyleBottomSheetOpen}
                onClose={handleCloseCutStyleBottomSheet}
                onFilterChange={handleFilterChange}
            />


            <div
                className={`${womenModalOpen && !isClosing ? 'opacity-100 ' : 'opacity-0'} ${womenModalTransiton.class}`}
            >
                {womenModalOpen && (
                    <div className={womenModalLayout.class}>
                        <div
                            ref={womenModalRef}
                            className={womenModalContainer.class}
                        >
                            <LegoSadImg />
                            <p className="mt-8 font-medium text-[#000000]">
                                여자 머리는 준비 중입니다...
                            </p>
                            <p className="mb-[35px] font-medium text-[#000000]">
                                죄송합니다...
                            </p>
                            <button
                                onClick={handleModalClose}
                                className="w-[328px] h-[41px] text-center rounded-[4px] bg-S_Dye_Black"
                            >
                                <p className="place-content-center text-lg font-semibold text-S_White">
                                    확인
                                </p>
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};
