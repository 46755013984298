import React from 'react';

interface BookMarkBtnProps {
    isActive?: boolean;
    onClick?: () => void;
}

export const BookMarkBtn: React.FC<BookMarkBtnProps> = ({
    isActive = false,
    onClick,
}) => {
    return (
        <button className="flex flex-col items-center px-4" onClick={onClick}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 18"
                fill="none"
                className="mt-[2px]"
            >
                <path
                    d="M15.4287 16.7143L9.0001 11.967L2.57153 16.7143V2.47252C2.57153 2.15776 2.74086 1.85589 3.04225 1.63332C3.34365 1.41074 3.75244 1.28571 4.17868 1.28571H13.8215C14.2478 1.28571 14.6566 1.41074 14.958 1.63332C15.2594 1.85589 15.4287 2.15776 15.4287 2.47252V16.7143Z"
                    stroke={isActive ? '#FF671F' : '#ACA39A'}
                    strokeWidth="1.7"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </button>
    );
};
