import React from 'react';
import { PrevIcon } from '@shared/icons/PrevIcon';
import { useNavigate } from 'react-router-dom';
import { API } from '../api/API';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { FeedPage, popFeedPage } from '../redux/slices/feed';
import { decreaseDepth } from '../redux/slices/depth';

interface PrevTopBarProps {
    isHidden?: boolean;
}

export const PrevTopBar: React.FC<PrevTopBarProps> = ({ isHidden = false }) => {
    const navigate = useNavigate();
    const currentFeedPage = useAppSelector(
        (state) => state.feed.currentFeedPage,
    );
    const prevFeedPages = useAppSelector((state) => state.feed.pastFeedPages);

    const dispatch = useAppDispatch();
    const sonigoId = useAppSelector((state) => state.user.sonigoId);

    const handleOnclick = () => {
        if (isHidden || !currentFeedPage) return;
        if (!prevFeedPages || prevFeedPages.length === 0) {
            return;
        }

        if (prevFeedPages && prevFeedPages.length > 0) {
            const lastPrevPageType = prevFeedPages[prevFeedPages.length - 1].pageType;

            switch (lastPrevPageType) {
                case FeedPage.HOME:
                case FeedPage.SEARCH:
                case FeedPage.BOOKMARK:
                    dispatch(popFeedPage());
                    break;
                default:
                    navigate(-1);
                    return;
            }
        }
        try {
            const killFunction = API.isAuthorized() ? API.feed.kill : API.feed.killNoAuth;
            killFunction(currentFeedPage.feed.id);
        } catch (e) {
            console.error('Error killing feed:', e);
        }

        dispatch(decreaseDepth());
        navigate(-1);
    };

    const handleHunsu = () => {
        if (sonigoId) {
            window.open(
                `https://tally.so/r/mKDAxA?sonigoId=${sonigoId}`,
                '_blank',
            );
            return;
        }

        window.open(`https://tally.so/r/mKDAxA`, '_blank');
    };

    return (
        <>
            <div className="max-w-[500px] w-full py-1 fixed left-1/2 transform -translate-x-1/2 bg-white z-30">
                <div className="flex items-center">
                    <button onClick={handleOnclick} className="pr-6 pl-4 py-3">
                        <PrevIcon />
                    </button>
                    <button
                        onClick={handleHunsu}
                        className="ml-auto px-6 py-1 text-S_Wave_Orange font-semibold text-sm underline hover:text-gray-400 focus:text-gray-100"
                    >
                        손이고 훈수두기
                    </button>
                </div>
                <div className="flex-grow"></div>
            </div>
        </>
    );
};
