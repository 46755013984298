import React from 'react';

// interface BookmarkIconProps {
//     fill: string;
//     stroke: string;
// }

export const BookmarkIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 19 19"
            fill='none'
        >
            <g clipPath="url(#clip0_630_1493)">
                <path
                    d="M15.9286 16.8397L9.49998 12.0925L3.07141 16.8397V2.59795C3.07141 2.28318 3.24073 1.98131 3.54213 1.75874C3.84353 1.53617 4.25231 1.41113 4.67855 1.41113H14.3214C14.7477 1.41113 15.1564 1.53617 15.4578 1.75874C15.7592 1.98131 15.9286 2.28318 15.9286 2.59795V16.8397Z"
                    stroke="#F2F1F0"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_630_1493">
                    <rect
                        width="18"
                        height="18"
                        fill="white"
                        transform="translate(0.5 0.125)"
                    />
                </clipPath>
            </defs>
        </svg>
    );
};
