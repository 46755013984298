import React from 'react';

interface HomeBtnProps {
    isActive?: boolean;
    onClick?: () => void;
}

export const HomeBtn: React.FC<HomeBtnProps> = ({
    isActive = false,
    onClick,
}) => {
    return (
        <button
            className="flex flex-col items-center gap-1 px-4"
            onClick={onClick}
        >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
            >
                <rect width="18" height="18" fill="#727272" />
                <g id="ë©ì¸">
                    <path
                        d="M-217 -321C-217 -376.228 -172.228 -421 -117 -421H3599C3654.23 -421 3699 -376.228 3699 -321V2204C3699 2259.23 3654.23 2304 3599 2304H-117C-172.229 2304 -217 2259.23 -217 2204V-321Z"
                        fill="#BCBCBC"
                    />
                    <path
                        d="M-117 -420H3599V-422H-117V-420ZM3698 -321V2204H3700V-321H3698ZM3599 2303H-117V2305H3599V2303ZM-216 2204V-321H-218V2204H-216ZM-117 2303C-171.676 2303 -216 2258.68 -216 2204H-218C-218 2259.78 -172.781 2305 -117 2305V2303ZM3698 2204C3698 2258.68 3653.68 2303 3599 2303V2305C3654.78 2305 3700 2259.78 3700 2204H3698ZM3599 -420C3653.68 -420 3698 -375.676 3698 -321H3700C3700 -376.781 3654.78 -422 3599 -422V-420ZM-117 -422C-172.781 -422 -218 -376.781 -218 -321H-216C-216 -375.676 -171.676 -420 -117 -420V-422Z"
                        fill="black"
                        fillOpacity="0.1"
                    />
                    <g id="interface-home-3--home-house-map-roof">
                        <rect width="18" height="18" fill="white" />
                        <path
                            id="Vector 22"
                            d="M1 9H3.06452V17H14.9355V9H17L9 2L1 9Z"
                            stroke={`${isActive ? '#FF671F' : '#ACA39A'}`}
                            strokeWidth="1.7"
                            strokeLinejoin="round"
                        />
                    </g>
                </g>
            </svg>
            {/* <p className={isActive ? 'text-S_Wave_Orange' : 'text-S_Dye_1'}>
                홈
            </p> */}
        </button>
    );
};
