import React from 'react';

interface UploadBtnProps {
    onClick?: () => void;
}

export const UploadBtn: React.FC<UploadBtnProps> = ({ onClick }) => {
    return (
        <button className="flex flex-col items-center justify-center px-4" onClick={onClick}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
            >
                <g clipPath="url(#clip0_334_960)">
                    <path
                        d="M9 16.5C13.1421 16.5 16.5 13.1421 16.5 9C16.5 4.85786 13.1421 1.5 9 1.5C4.85786 1.5 1.5 4.85786 1.5 9C1.5 13.1421 4.85786 16.5 9 16.5Z"
                        stroke="#ACA39A"
                        strokeWidth="1.7"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M9 5.53845V12.4615"
                        stroke="#ACA39A"
                        strokeWidth="1.7"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M5.53857 9H12.4617"
                        stroke="#ACA39A"
                        strokeWidth="1.7"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </g>
                <defs>
                    <clipPath id="clip0_334_960">
                        <rect width="18" height="18" fill="white" />
                    </clipPath>
                </defs>
            </svg>
            {/* <p className="text-S_Dye_1">업로드</p> */}
        </button>
    );
};
