import { API } from "../../api/API";

export const killFeed = async (feedId: string) => {
    try {
        if (API.isAuthorized()) {
            await API.feed.kill(feedId);
        } else {
            await API.feed.killNoAuth(feedId);
        }
    } catch (e) {
        console.log(e);
    }
};