import { createSlice } from '@reduxjs/toolkit';

export const forwardSlice = createSlice({
    name: 'FORWARD',
    initialState: {
        forward: false,
    },
    reducers: {
        setForward: (state) => {
            state.forward = true;
        },
        unsetForward: (state) => {
            state.forward = false;
        },
    },
});

export const {
    setForward,
    unsetForward,
} = forwardSlice.actions;
export default forwardSlice.reducer;