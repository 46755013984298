import React from 'react';
import { InstagramVisitBtn } from '@shared/buttons/InstagramVisitBtn';
import { ShareIcon } from '@shared/icons/ShareIcon';
import { BookmarkReqBtn } from '@shared/buttons/ActionToolBar/BookmarkReqBtn';
import { ContainReqBtn } from '@shared/buttons/ActionToolBar/ContainReqBtn';
import { LikeReqBtn } from '@shared/buttons/ActionToolBar/LikeReqBtn';
import { buttonExperimentFlagKey, experiment } from '@app/App';

interface ActionToolBarProps {
    isBookmarked: boolean;
    onBookmarkToggle: () => void;
    shareUrl: string;
    shareTitle: string;
    postId: string | undefined;
    shareCallback?: () => void;
    instagramUrl: string;
}

export const ActionToolBar: React.FC<ActionToolBarProps> = ({
    isBookmarked,
    onBookmarkToggle,
    shareUrl,
    shareTitle,
    postId,
    shareCallback,
    instagramUrl,
}) => {
    const varient = experiment.variant(buttonExperimentFlagKey);
    
    let bookmarkBtn: () => JSX.Element;
    switch (varient.value) {
        case 'save_type':
            bookmarkBtn = () => (
                <BookmarkReqBtn
                    text={isBookmarked ? '저장하기 취소' : '저장하기'}
                    isBookmarked={isBookmarked}
                />
            );
            break;
        case 'like_type':
            bookmarkBtn = () => (
                <LikeReqBtn text={isBookmarked ? '좋아요 취소' : '좋아요'} 
                isBookmarked={isBookmarked}
                />
            );
            break;
        case 'collect_type':
            bookmarkBtn = () => (
                <ContainReqBtn
                    text={isBookmarked ? '스타일 담기 취소' : '스타일 담기'}
                    isBookmarked={isBookmarked}
                />
            );
            break;
        default:
            bookmarkBtn = () => (
                <BookmarkReqBtn
                    text={isBookmarked ? '저장하기 취소' : '저장하기'}
                    isBookmarked={isBookmarked}
                />
            );
            break;
    }

    const handleShare = async () => {
        if (navigator.share) {
            try {
                await navigator.share({
                    title: shareTitle,
                    url: shareUrl,
                });
                console.log('Content shared successfully');
                if (shareCallback) {
                    shareCallback();
                }
            } catch (error) {
                console.log('Error sharing content:', error);
            }
        } else {
            console.log('Web Share API not supported');
        }
    };

    return (
        <div className="flex justify-center items-center gap-[10px] py-[12px] relative">
            <button
                onClick={onBookmarkToggle}
                className="w-full rounded-[4px] flex items-center justify-center"
            >
                {bookmarkBtn()}
            </button>
            <button
                className="py-2 px-2 bg-S_Dye_White rounded-[4px]"
                onClick={handleShare}
            >
                <ShareIcon />
            </button>
            <InstagramVisitBtn instagramUrl={instagramUrl} />
        </div>
    );
};
