import { AxiosResponse } from 'axios';
import { API, IFeedResponse } from '../api/API';
import { Post } from '../redux/slices/feed';

export const fetchByTags = async (
    selectedTags: string[],
    feedId?: string,
    pageNumber?: number,
): Promise<{
    hasMore: boolean,
    feedId: string | null,
    posts: Post[],
    page: number | null,
}> => {
    let isInitial = false;
    if (!selectedTags) return null;

    if (!pageNumber) isInitial = true;
    if (!feedId) isInitial = true;

    let res: AxiosResponse<IFeedResponse>;

    if (isInitial) {
        try {
            if (API.isAuthorized()) {
                res = await API.feed.search.search(selectedTags);
            } else {
                res = await API.feed.searchNoAuth.search(selectedTags);
            }

            if (res.data.statusCode !== 200 || res.data.message !== 'success') {
                throw new Error('API response was not successful');
            }
        } catch (error) {
            return {
                hasMore: false,
                feedId: null,
                posts: [],
                page: null,
            }
        }
    } else {
        try {
            if (API.isAuthorized()) {
                res = await API.feed.search.next(feedId, pageNumber, selectedTags);
            } else {
                res = await API.feed.searchNoAuth.next(feedId, pageNumber, selectedTags);
            }

            if (res.data.statusCode !== 200 || res.data.message !== 'success') {
                throw new Error('API response was not successful');
            }
        } catch (error) {
            return {
                hasMore: false,
                feedId: null,
                posts: [],
                page: pageNumber === 1 ? 1 : pageNumber - 1,
            }
        }
    }

    const {
        feedId: newFeedId,
        posts,
        page,
    } = res.data.result;

    const parsedPosts = posts.map((post) => {
        return {
            id: post.postId,
            uploaderId: post.uploaderSonigoId,
            imageUrl: post.imageUrl,
            tags: post.tags,
        } as Post
    });

    return {
        hasMore: posts.length > 0,
        feedId: newFeedId,
        posts: parsedPosts,
        page,
    };
}