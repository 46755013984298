/* eslint-disable*/

import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { DetailSelectBar } from '@components/DetailSelectBar';
import { PrevTopBar } from '@components/PrevTopBar';
import { ActionToolBar } from '@components/ActionToolBar';
import { CloseIcon } from '@shared/icons/CloseIcon';
import {
    useLocation,
    useNavigate,
    Location,
    useBeforeUnload,
} from 'react-router-dom';
import { API, IPost } from '../api/API';
import Masonry from 'react-masonry-css';
import Lottie from 'lottie-react';
import scissor from '@shared/icons/scissors.json';
import { ampli } from '../ampli';
import { LoginReferer, useLoginTracking } from '@shared/hooks/usePrevTracking';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import {
    decreaseDepth,
    increaseDepth,
    refreshDepth,
} from '../redux/slices/depth';
import feed, {
    Feed,
    FeedPage,
    RelatedFeedPageState,
    addFeedPage,
    addPostsToCurrentFeed,
    stashCurrentFeedToPastFeeds,
    swapCurrentFeedPageWithRelatedFeedPage,
    popFeedPage,
    refreshFeedPage,
} from '../redux/slices/feed';
import { UnknownAction } from '@reduxjs/toolkit';
import {
    fetchByPostIdWithTagsAndPageNumber,
    fetchCurrentPostDetail,
} from './detail.api';
import { PostItem } from '../components/PostItem';
import { InfiniteScroll } from '@shared/utils/InfiniteScroll';
import ImageSkeleton from '@components/ImageSkeleton';
import forward, { setForward, unsetForward } from '../redux/slices/forward';
import { BottomBar } from '@components/BottomBar';
import { useImageZoom } from '@shared/hooks/useImageZoom.hook';
import { setRefresh, unsetRefresh } from '../redux/slices/refresh';
import {
    LoginRecommendModalContainer,
    LoginRecommendModalLayout,
    LoginRecommendModalTransiton,
} from '@shared/style/LoginRecommendModal.styled';
import { LegoShyImg } from '@shared/imges/LegoShyImg';
import { useModal } from '@shared/hooks/useModal.hook';
import { useOutsideClick } from '@shared/hooks/useOutsideClick.hook';
import { LinkCopyBottomSheet } from '@components/LinkCopyBottomSheet';
import { Toast } from '@components/Toast';
import { scrollToTop } from '@shared/utils/scrollToTop';
import { killFeed } from '@shared/utils/killFeed';
import { AnimatePresence } from 'framer-motion';

function getPostIdFromLocation(location: Location): string {
    const fullPathString = location.pathname.split('/').pop();
    // trim possible query string
    return fullPathString?.split('?')[0];
}

export const Detail = () => {
    const location = useLocation();
    const currentPostId = getPostIdFromLocation(location);
    const navigate = useNavigate();
    const LoginRecommendModalRef = useRef<HTMLDivElement>(null);

    const {
        isModalOpen: LoginRecommendModalOpen,
        openModal: LoginRecommendOpenModal,
        closeModal: LoginRecommendCloseModal,
    } = useModal<null>(null);

    useOutsideClick(
        LoginRecommendModalRef,
        LoginRecommendCloseModal,
        LoginRecommendModalOpen,
    );

    const { containerRef, imgRef, toggleZoom, isZoomed, handleMouseMove } =
        useImageZoom();

    // Redux store
    const dispatch = useAppDispatch();
    const depth = useAppSelector((state) => state.depth.depth);
    const origin = useAppSelector((state) => state.depth.origin);
    const feedPage = useAppSelector(
        (state) => state.feed.currentFeedPage,
    ) as RelatedFeedPageState | null;
    const isRefresh = useAppSelector((state) => state.refresh.refresh);

    const [isBookmarked, setIsBookmarked] = useState<boolean>(false);
    const [isBookmarking, setIsBookmarking] = useState<boolean>(false);
    const [postInfo, setPostInfo] = useState<IPost | null>(null);
    const [selectedTags, setSelectedTags] = useState<string[]>(
        feedPage ? feedPage.selectedTags : [],
    );
    const [hasFeed, setHasFeed] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [hasMore, setHasMore] = useState<boolean>(true);
    const [instagramUrl, setInstagramUrl] = useState<string>(
        'https://instagram.com',
    );

    const [showSheet, setShowSheet] = useState(false);
    const [hasShownSheet, setHasShownSheet] = useState(false);

    const [showToast, setShowToast] = useState<boolean>(false);
    const [isClosing, setIsClosing] = useState(false);
    const isForward = useAppSelector((state) => state.forward.forward);
    const tagScrollRef = useRef<HTMLDivElement>(null);

    const [initialized, setInitialized] = useState<number>(0);

    const [twoDepthChecked, setTwoDepthChecked] = useState<boolean>(false);

    const breakpointColumnsObj = useMemo(
        () => ({
            default: 2,
            1100: 2,
            700: 2,
            500: 2,
        }),
        [],
    );

    const handleNav = useCallback(
        (action: 'back' | 'refresh') => {
            setLoading(true);
            setHasFeed(false);

            if (action === 'refresh') {
                console.log('새로고침됐다');
                dispatch(refreshFeedPage());
                dispatch(refreshDepth());
                setSelectedTags(feedPage ? feedPage.selectedTags : []);
                return;
            } else if (action === 'back') {
                console.log('뒤로갔다');
                dispatch(popFeedPage());
                dispatch(decreaseDepth());
                dispatch(unsetForward());
                setInitialized(initialized + 1);
                initializePostView();
                setSelectedTags(feedPage ? feedPage.selectedTags : []);
                setLoading(false);
                setHasFeed(true);
                setInitialized(initialized + 1);
                scrollToTop();
                return;
            }

            setLoading(false);
            setHasFeed(true);
            setInitialized(initialized + 1);
        },
        [dispatch],
    );

    useEffect(() => {
        const handlePopState = () => {
            handleNav('back');
        };

        window.addEventListener('popstate', handlePopState);
        return () => {
            window.removeEventListener('popstate', handlePopState);
        };
    }, [handleNav]);

    // 새로고침 감지
    useBeforeUnload((e) => {
        dispatch(setRefresh());
        e.preventDefault();
    });
    // 새로고침 인지
    useEffect(() => {
        if (isRefresh) {
            handleNav('refresh');
        }
    }, [isRefresh, handleNav]);

    useEffect(() => {
        console.log('호출됨');
        initializePostView();
        initializeFeed();
        setSelectedTags(feedPage ? feedPage.selectedTags : []);
    }, [currentPostId]);

    useEffect(() => {
        console.log('initial useEffect');
        if (!isForward) {
            dispatch(increaseDepth());
        }
        dispatch(setForward());
        logLoginOrigin(LoginReferer.SAVE_BUTTON);
    }, []);

    useEffect(() => {
        const timer = setTimeout(() => {
            const loggin = API.isAuthorized();
            if (depth === 2 && !twoDepthChecked && !loggin) {
                setTwoDepthChecked(true);
                LoginRecommendOpenModal();
            }
        }, 1000);

        console.log('twoDepthChecked: ', twoDepthChecked);
        return () => clearTimeout(timer);
    }, [depth, twoDepthChecked]);

    useEffect(() => {
        // check if "instagram" or "Instagram" is in the user agent
        if (!window.navigator.userAgent.toLowerCase().includes("instagram")) {
            return;
        }

        if (!hasShownSheet && postInfo) {
            setShowSheet(true);
            setHasShownSheet(true);
        }
    }, [hasShownSheet, postInfo]);

    useEffect(() => {
        if (postInfo) {
            const hasShownBefore = sessionStorage.getItem('hasShownLinkCopySheet');
            
            // check if "instagram" or "Instagram" is in the user agent
            if (!window.navigator.userAgent.toLowerCase().includes("instagram")) {
                return;
            }

            if (!hasShownBefore) {
                setShowSheet(true);
            }
        }
    }, [postInfo]);

    useEffect(() => {
        // check if "instagram" or "Instagram" is in the user agent
        if (!window.navigator.userAgent.toLowerCase().includes("instagram")) {
            return;
        }

        if (showSheet) {
            ampli.링크복사화면띄워짐();
        }
    }, [showSheet]);

    const initializePostView = async () => {
        const {
            postId,
            isBookmarked,
            instagramUrl,
            imageUrl,
            tags,
            uploaderSonigoId,
        } = await fetchCurrentPostDetail(currentPostId);

        setPostInfo({
            postId: postId,
            imageUrl: imageUrl,
            uploaderSonigoId: uploaderSonigoId,
            tags: tags,
        });

        setIsBookmarked(isBookmarked);
        setInstagramUrl(instagramUrl);
    };

    const initializeFeed = async () => {
        console.log('forward', isForward);

        dispatch(unsetForward());
        dispatch(unsetRefresh());

        if (feedPage) {
            setHasFeed(true);
            setLoading(false);
            setInitialized(initialized + 1);
            return;
        } else {
            setLoading(true);

            const { hasMore, feedId, postId, posts, page, tags } =
                await fetchByPostIdWithTagsAndPageNumber(currentPostId, [], 1);

            let action: UnknownAction;

            if (!hasMore) {
                setHasMore(false);
                action = addFeedPage({
                    pageType: FeedPage.RELATED,
                    loading: false,
                    postId: postId,
                    feed: null,
                    selectedTags: tags,
                } as RelatedFeedPageState);
            } else {
                setHasMore(true);
                action = addFeedPage({
                    pageType: FeedPage.RELATED,
                    loading: false,
                    postId: postId,
                    feed: {
                        id: feedId,
                        posts: posts,
                        lastPage: page,
                    },
                    selectedTags: tags,
                } as RelatedFeedPageState);
            }

            dispatch(action);
            setLoading(false);
            setHasFeed(true);
            setInitialized(initialized + 1);
        }
    };

    const loadMore = useCallback(async () => {
        if (!loading && hasMore) {
            setLoading(true);

            const { hasMore, feedId, posts, page } =
                await fetchByPostIdWithTagsAndPageNumber(
                    currentPostId,
                    feedPage && feedPage.selectedTags
                        ? feedPage.selectedTags
                        : selectedTags,
                    feedPage && feedPage.feed && feedPage.feed.lastPage + 1,
                    feedPage && feedPage.feed && feedPage.feed.id,
                );
            let action: UnknownAction;

            if (!hasMore) {
                setHasMore(false);
                action = addPostsToCurrentFeed({
                    id: feedId,
                    posts: [],
                    lastPage: page,
                } as Feed);
            } else {
                setHasMore(true);
                action = addPostsToCurrentFeed({
                    id: feedId,
                    posts: posts,
                    lastPage: page,
                } as Feed);
            }

            dispatch(action);
            setLoading(false);
        }
    }, [loading, hasMore]);

    const refreshFeedWithNewTags = async (tags: string[]) => {
        setLoading(true);
        setHasFeed(false);

        const { hasMore, feedId, posts, page } =
            await fetchByPostIdWithTagsAndPageNumber(currentPostId, tags, 1);
        let action: UnknownAction;

        if (!hasMore) {
            setHasMore(false);
            action = swapCurrentFeedPageWithRelatedFeedPage({
                pageType: FeedPage.RELATED,
                loading: false,
                postId: currentPostId,
                feed: null,
                selectedTags: tags,
            } as RelatedFeedPageState);
        } else {
            setHasMore(true);
            action = swapCurrentFeedPageWithRelatedFeedPage({
                pageType: FeedPage.RELATED,
                loading: false,
                postId: currentPostId,
                feed: {
                    id: feedId,
                    posts: posts,
                    lastPage: page,
                },
                selectedTags: tags,
            } as RelatedFeedPageState);
        }

        dispatch(action);
        setLoading(false);
        setHasFeed(true);
    };

    // 태그 선택 토글
    const handleSelect = async (tag: string) => {
        if (loading) return;

        const selectedTags = feedPage.selectedTags;

        if (selectedTags.includes(tag)) return;

        const newTags = [...selectedTags, tag];
        setSelectedTags(newTags);

        refreshFeedWithNewTags(newTags);

        killFeed(feedPage.feed.id);
    };

    // 태그 삭제
    const handleRemoveTag = async (tagToRemove: string) => {
        if (loading) return;

        const newTags = feedPage.selectedTags.filter(
            (tag) => tag !== tagToRemove,
        );

        refreshFeedWithNewTags(newTags);
        setSelectedTags(newTags);

        killFeed(feedPage.feed.id);
    };

    const { logLoginOrigin } = useLoginTracking();

    // 사진 저장 토글 버튼
    const handleBookmarkToggle = async () => {
        if (!API.isAuthorized()) {
            console.log('로그인 안됨');
            logLoginOrigin(LoginReferer.SAVE_BUTTON);

            navigate('/login?toast=로그인 후 사용이 가능해요.&where=detail');
            return;
        }

        if (isBookmarking) return;

        setIsBookmarking(true);
        try {
            if (!isBookmarked) {
                await API.bookmark.save(postInfo?.postId);
                ampli.포스트저장({
                    depth: depth,
                    origin: origin,
                    ownedBy: postInfo.uploaderSonigoId,
                    postId: postInfo.postId,
                    tags: postInfo.tags,
                });
                setIsBookmarked(!isBookmarked);
                console.log('저장 성공');
            } else {
                await API.bookmark.unsave(postInfo?.postId);
                ampli.포스트저장취소({
                    depth: depth,
                    origin: origin,
                    ownedBy: postInfo.uploaderSonigoId,
                    postId: postInfo.postId,
                    tags: postInfo.tags,
                });
                setIsBookmarked(!isBookmarked);
                console.log('저장 해제 성공');
            }
        } finally {
            setIsBookmarking(false);
        }
    };

    const handleImgClick = (postId: string) => {
        if (loading) return;

        dispatch(setForward());
        const clickedPost = feedPage.feed.posts.find(
            (post) => post.id === postId,
        );
        ampli.포스트자세히보기썸네일클릭({
            depth: depth,
            origin: origin,
            postId: postId,
            tags: clickedPost?.tags,
        });

        dispatch(stashCurrentFeedToPastFeeds());
        dispatch(increaseDepth());
        navigate(`/detail/${postId}`, {
            state: { isForward: true },
            replace: false,
        });
        scrollToTop();
    };

    const handleModalClose = () => {
        setIsClosing(true);
        setTimeout(() => {
            LoginRecommendCloseModal();
            setIsClosing(false);
        }, 200);
    };

    const handleLoginNavigate = () => {
        LoginRecommendCloseModal();
        // 상태값 넘겨줘서 들어가면 네이베이션 -1로
        navigate('/login?&where=login_modal');
        // 뎁스 2일 때 한 번만 뜨는 값 초기화
        setTwoDepthChecked(false);
    };

    const handleCloseLinkCopyBottomSheet = () => {
        setShowSheet(false);
        sessionStorage.setItem('hasShownLinkCopySheet', 'true');
    };

    const handleCopyLink = () => {
        try {
            ampli.링크복사버큰클릭();
            navigator.clipboard.writeText(window.location.href);
            handleCloseLinkCopyBottomSheet;
            setShowToast(true);
        } catch (err) {
            console.log('링크 복사에 실패했습니다. 다시 시도해주세요.');
        }
    };

    const tagView = () => {
        let tags = [];
        if (selectedTags && selectedTags.length > 0) {
            tags = selectedTags;
        } else if (
            feedPage &&
            feedPage.selectedTags &&
            feedPage.selectedTags.length > 0
        ) {
            tags = feedPage.selectedTags;
        }

        return (
            <div
                className={
                    'w-full px-2 flex flex-wrap gap-2 sticky top-10 z-40 bg-white pl-5 ' +
                    (tags && tags.length > 0 && 'pb-2 mb-1')
                }
            >
                {tags.map((tag) => (
                    <button
                        key={tag}
                        className={`py-2 px-[14px] border-[0.5px] border-S_Dye_White rounded-full
                    bg-S_WO_20 text-sm font-medium text-S_Wave_Orange shadow-[inset_0px_-2px_1px_0px_#FFBE9F]
                    flex items-center scroll-mt-16`}
                        onClick={() => handleRemoveTag(tag)}
                    >
                        {tag}
                        <span className="ml-[6px]">
                            <CloseIcon />
                        </span>
                    </button>
                ))}
            </div>
        );
    };

    return (
        <>
            <PrevTopBar isHidden={loading} />
            {postInfo && (
                <div>
                    <div
                        ref={containerRef}
                        className="w-full h-0 pb-[100%] relative overflow-hidden mt-12"
                    >
                        <img
                            ref={imgRef}
                            src={postInfo.imageUrl}
                            alt="detail_images"
                            className={`w-full h-full absolute top-0 left-0 object-cover ${isZoomed ? 'cursor-zoom-out' : 'cursor-zoom-in'}`}
                            onClick={toggleZoom}
                            onMouseMove={handleMouseMove}
                        />
                    </div>
                    <div className="w-full px-2 pb-2">
                        <div className="p-2">
                            <DetailSelectBar
                                key={postInfo.tags?.join('')}
                                onSelect={handleSelect}
                                postInfoTags={
                                    postInfo.tags ? postInfo.tags : []
                                }
                            />
                            <ActionToolBar
                                isBookmarked={isBookmarked}
                                onBookmarkToggle={handleBookmarkToggle}
                                shareUrl={window.location.href}
                                shareTitle="공유"
                                postId={postInfo.postId}
                                shareCallback={() => {
                                    ampli.포스트공유시도({
                                        depth: depth,
                                        origin: origin,
                                        ownedBy: postInfo.uploaderSonigoId,
                                        postId: postInfo.postId,
                                        tags: postInfo.tags,
                                    });
                                }}
                                instagramUrl={instagramUrl}
                            />
                        </div>
                        <div className="max-w-[500px] w-full h-[6px] bg-S_Dye_White absolute left-1/2 transform -translate-x-1/2"></div>
                    </div>
                    <div
                        className="w-full px-2 mb-2 scroll-mt-[55px]"
                        ref={tagScrollRef}
                    >
                        <p className="ml-3 mt-3 font-bold text-lg text-S_Dye_Black">
                            유사한 스타일
                        </p>
                    </div>
                    {tagView()}
                    <div className="w-full px-3">
                        {hasFeed ? (
                            <InfiniteScroll
                                loadMore={loadMore}
                                hasMore={hasMore}
                                isLoading={loading}
                            >
                                <Masonry
                                    breakpointCols={breakpointColumnsObj}
                                    className="my-masonry-grid"
                                    columnClassName="my-masonry-grid_column"
                                >
                                    {feedPage && feedPage.feed
                                        ? feedPage.feed.posts
                                              // duplicate post filtering
                                              .filter((e, index) => {
                                                  return (
                                                      feedPage.feed.posts
                                                          .map((d) => d.id)
                                                          .indexOf(e.id) ===
                                                      index
                                                  );
                                              })
                                              .map((d) => (
                                                  <PostItem
                                                      key={d.id}
                                                      postId={d.id}
                                                      imageUrl={d.imageUrl}
                                                      tags={d.tags}
                                                      uploaderId={d.uploaderId}
                                                      onClick={handleImgClick}
                                                  />
                                              ))
                                        : null}
                                </Masonry>
                            </InfiniteScroll>
                        ) : null}
                    </div>

                    {loading && (
                        <div className="col-span-full w-full pb-3">
                            <div className="flex justify-center mb-4">
                                <div className="w-12 h-12">
                                    <Lottie
                                        animationData={scissor}
                                        loop={true}
                                        autoplay={true}
                                    />
                                </div>
                            </div>
                            <div className="flex flex-wrap justify-center gap-2 ">
                                {Array.from({ length: 6 }).map((_, index) => (
                                    <ImageSkeleton
                                        key={`skeleton-${index}`}
                                        showScissors={false}
                                        className={
                                            'w-[calc(46vw-8px)] sm:w-[240px] h-[200px] sm:h-[300px] aspect-[3/4] rounded-lg'
                                        }
                                    />
                                ))}
                            </div>
                        </div>
                    )}
                </div>
            )}
            <div className="sticky w-full left-0 right-0 bottom-16 z-30">
                {showToast && (
                    <Toast
                        message={'링크가 복사되었어요.'}
                        duration={2000}
                        onClose={() => setShowToast(false)}
                    />
                )}
            </div>

            <div className="fixed max-w-[500px] w-full bottom-0 left-1/2 transform -translate-x-1/2 z-40">
                <BottomBar activePage={origin !== 'home' ? '/search' : '/'} />
            </div>

            {!sessionStorage.getItem('hasShownLinkCopySheet') && (
                <LinkCopyBottomSheet
                    onClose={handleCloseLinkCopyBottomSheet}
                    isOpen={showSheet}
                    onClick={handleCopyLink}
                />
            )}

            <div
                className={`${LoginRecommendModalOpen && !isClosing ? 'opacity-100 ' : 'opacity-0'} ${LoginRecommendModalTransiton.class}`}
            >
                {LoginRecommendModalOpen && (
                    <div className={LoginRecommendModalLayout.class}>
                        <div
                            ref={LoginRecommendModalRef}
                            className={LoginRecommendModalContainer.class}
                        >
                            <div className="relative mb-3 rounded-lg py-4 px-[51px] text-center bg-[#FFE1D2]">
                                <p className="font-medium text-lg text-S_Wave_Orange">
                                    3초만에 전화번호로 로그인 하고 <br /> 맞춤형
                                    피드 받을래...?
                                </p>
                                <div className="absolute -bottom-2 left-1/2 w-4 h-4 bg-[#FFE1D2] transform rotate-45 -translate-x-1/2"></div>
                            </div>
                            <LegoShyImg />
                            <p className="mt-2 font-medium text-[12px] text-S_Dye_1">
                                저,,전화번호좀 알려줘,,,!
                            </p>
                            <p className="mb-4 font-medium text-[12px] text-S_Dye_1">
                                따,,딱히 너가 좋아서 그런건 아니야,,!
                            </p>
                            <button
                                onClick={() => handleLoginNavigate()}
                                className="w-[328px] py-2 text-center rounded-[4px] bg-S_Dye_Black"
                            >
                                <p className="place-content-center text-lg font-semibold text-S_White">
                                    우횻!!(로그인하기)
                                </p>
                            </button>
                        </div>
                        <p
                            className="mt-2 font-medium text-white cursor-pointer"
                            onClick={handleModalClose}
                        >
                            <span className="border-b border-white">
                                번호.곤란.(닫기)
                            </span>
                        </p>
                    </div>
                )}
            </div>
        </>
    );
};

