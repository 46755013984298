import React from 'react';
import Lottie from 'lottie-react';
import scissor from '@shared/icons/scissors.json';

export interface ImageSkeletonProps {
    showScissors?: boolean;
    className?: string;
    height?: string;
}

const ImageSkeleton: React.FC<ImageSkeletonProps> = ({ showScissors = true, className = "", }) => {
    return (
        <div className={`aspect-square relative bg-gray-200 animate-pulse rounded-lg ${className} `}>
            {showScissors && (
                <div className="absolute inset-0 flex justify-center items-center">
                    <div className="w-12 h-12">
                        <Lottie
                            animationData={scissor}
                            loop={true}
                            autoplay={true}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export default ImageSkeleton;