import React, { useState } from 'react'
import { ContainIcon } from '@shared/icons/ContainIcon';
import { Tooltip } from '@shared/Tooltip';


interface ContainReqBtnProps {
    text: string;
    isBookmarked:boolean;
}

export const ContainReqBtn: React.FC<ContainReqBtnProps> = ({ text, isBookmarked }) => {
    const tooltipText = "원하는 스타일을 담으면 맞춤형 피드가 완성돼요.";
    const [showTooltip, setShowTooltip] = useState(true);

    const handleCloseTooltip = (e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        setShowTooltip(false);
    };

    return (
        <>
            {showTooltip && (
                <div className="absolute top-[-28%] left-[0%]">
                    <Tooltip
                        text={tooltipText}
                        arrowPosition={30}
                        onClose={handleCloseTooltip}
                        isVisible={showTooltip}
                    />
                </div>
            )}
            <div
                className={`w-full rounded-[4px] flex items-center justify-center py-[11px]
                ${isBookmarked ? 'bg-S_Dye_1' : 'bg-S_Dye_5'}
                `}
            >
                <ContainIcon />
                <p className="ml-[10px] font-medium text-S_White text-sm">
                    {text}
                </p>
            </div>
        </>
    )
}