import { PayloadAction, createSlice, current } from '@reduxjs/toolkit';

export interface Post {
    id: string;
    uploaderId: string;
    imageUrl: string;
    tags: string[];
}

export interface Feed {
    id: string;
    posts: Post[];
    lastPage: number;
}

export interface FeedUsingPage {
    loading: boolean;
    feed: Feed | null;
    pageType: FeedPage;
    selectedTags?: string[];
}

export const FeedPage = {
    HOME: 'home',
    RELATED: 'related',
    SEARCH: 'search',
    BOOKMARK:'bookmark'
} as const;
export type FeedPage = (typeof FeedPage)[keyof typeof FeedPage];

export interface RelatedFeedPageState extends FeedUsingPage {
    postId: string;
    selectedTags: string[];
}

export interface HomeFeedPageState extends FeedUsingPage {
    loading: boolean;
    feed: Feed | null;
    keyword: string;
}

export interface SearchFeedPageState extends FeedUsingPage {
    loading: boolean;
    feed: Feed | null;
    category: string;
    filters: Record<string, string[]>;
}

export interface PiledFeedState {
    pastFeedPages: FeedUsingPage[];
    currentFeedPage: FeedUsingPage | null;
}

const INITIAL_STATE: PiledFeedState = {
    pastFeedPages: [],
    currentFeedPage: null,
};

export const feedSlice = createSlice({
    name: 'FEED',
    initialState: INITIAL_STATE,
    reducers: {
        addFeedPage: (state, action: PayloadAction<FeedUsingPage>) => {
            if (state.currentFeedPage) {
                state.pastFeedPages.push(current(state.currentFeedPage));
            }
            state.currentFeedPage = action.payload;
        },
        popFeedPage: (state) => {
            if (state.currentFeedPage) {
                state.currentFeedPage = null;
            }

            if (state.pastFeedPages.length > 0) {
                state.currentFeedPage = state.pastFeedPages.pop();
            }
        },
        refreshFeedPage: (state, action: PayloadAction<FeedUsingPage>) => {
            state.pastFeedPages = [...state.pastFeedPages];
            state.currentFeedPage = action.payload;
        },
        clearFeedPages: (state) => {
            state.pastFeedPages = [];
            state.currentFeedPage = null;
        },
        setLoadingForCurrentFeed: (state) => {
            if (state.currentFeedPage) {
                state.currentFeedPage.loading = true;
            }
        },
        unsetLoadingForCurrentFeed: (state) => {
            if (state.currentFeedPage) {
                state.currentFeedPage.loading = false;
            }
        },
        addPostsToCurrentFeed: (state, action: PayloadAction<Feed>) => {
            if (state.currentFeedPage) {
                state.currentFeedPage.feed.posts.push(...action.payload.posts);
                state.currentFeedPage.feed.lastPage = action.payload.lastPage;
            }
        },
        stashCurrentFeedToPastFeeds: (state) => {
            if (state.currentFeedPage) {
                state.pastFeedPages.push(current(state.currentFeedPage));
                state.currentFeedPage = null;
            }
        },
        swapCurrentFeedPageWithRelatedFeedPage: (
            state,
            action: PayloadAction<RelatedFeedPageState>,
        ) => {
            state.currentFeedPage = action.payload;
        },
        swapCurrentFeedPageWithHomeFeedPage: (
            state,
            action: PayloadAction<HomeFeedPageState>,
        ) => {
            state.currentFeedPage = action.payload;
        },
        swapCurrentFeedPageWithSearchFeedPage: (
            state,
            action: PayloadAction<SearchFeedPageState>,
        ) => {
            state.currentFeedPage = action.payload;
        },
    },
});

export const {
    addFeedPage,
    popFeedPage,
    refreshFeedPage,
    clearFeedPages,
    setLoadingForCurrentFeed,
    unsetLoadingForCurrentFeed,
    addPostsToCurrentFeed,
    stashCurrentFeedToPastFeeds,
    swapCurrentFeedPageWithRelatedFeedPage,
    swapCurrentFeedPageWithHomeFeedPage,
    swapCurrentFeedPageWithSearchFeedPage,
} = feedSlice.actions;

export default feedSlice.reducer;
