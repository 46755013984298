import { createSlice } from "@reduxjs/toolkit";

export interface DepthState {
    depth: number;
    origin: Origin;
}

export const Origin = {
    HOME: 'home',
    SEARCH: 'search',
    SAVE: 'save',
    ORGANIC: 'organic',
}
export type Origin = typeof Origin[keyof typeof Origin];

const INITIAL_STATE: DepthState = {
    depth: 0,
    origin: Origin.ORGANIC,
};

export const depthSlice = createSlice({
    name: "DEPTH",
    initialState: INITIAL_STATE,
    reducers: {
        setInitialDepth: (state) => {
            state.depth = 0;
        },
        increaseDepth: (state) => {
            state.depth += 1;
        },
        decreaseDepth: (state) => {
            if (state.depth === 0) {
                return;
            }
            state.depth -= 1;
        },
        refreshDepth: (state) => {
            const originalDepth = state.depth;
            state.depth += 1;
            state.depth = originalDepth;
        },
        setOrganicOrigin: (state) => {
            state.origin = Origin.ORGANIC;
        },
        setHomeOrigin: (state) => {
            state.origin = Origin.HOME;
        },
        setSearchOrigin: (state) => {
            state.origin = Origin.SEARCH;
        },
        setSaveOrigin: (state) => {
            state.origin = Origin.SAVE;
        },
    }
});

export const {
    setInitialDepth,
    increaseDepth,
    decreaseDepth,
    refreshDepth,
    setOrganicOrigin,
    setHomeOrigin,
    setSearchOrigin,
    setSaveOrigin,
} = depthSlice.actions;

export default depthSlice.reducer;