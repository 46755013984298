import React from 'react';
import { ampli } from '../../ampli';
import { InstagramIcon } from '@shared/icons/InstagramIcon';

interface InstagramVisitBtnProps {
    instagramUrl: string;
}

export const InstagramVisitBtn = ({ instagramUrl }: InstagramVisitBtnProps) => {
    const handleBtn = () => {
        ampli.인스타그램방문버튼클릭({
            url: instagramUrl,
        });
        window.open(
            instagramUrl,
            '_blank',
            'noopener,noreferrer',
        );
    };
    return (
        <button className="py-2 px-2 bg-S_Dye_White rounded-[4px]" onClick={handleBtn}>

            <InstagramIcon />
        </button>
    );
};
