import React from 'react';
import { createBrowserRouter } from 'react-router-dom';
import 'tailwindcss/tailwind.css';
import { Main } from '@pages/Main';
import { Detail } from '@pages/Detail';
import { Search } from "@pages/Search";
import { SearchDetail } from "@pages/SearchDetail";
import { Login } from '@pages/Login';
import { NickName } from '@pages/NickName';
import { Upload } from '@pages/Upload';
import { Bookmark } from '@pages/Bookmark';
import { Layout } from '@app/layout/Layout';

const CustomRouter = createBrowserRouter([
    {
        path: '/',
        element: <Layout />,
        errorElement: <span>404</span>,
        children: [
            {
                path: '',
                element: <Main />,
            },
            {
                path: "detail/:postId",
                element: <Detail />,
            },
            {
                path: "search",
                element: <Search />,
            },
            {
                path: "search-cut-detail",
                element: <SearchDetail
                    title={"커트"}
                    filterData={{
                        기장: ['스킨헤드', '짧은 머리', '눈썹 위', '눈 주변', '거지존', '장발'],
                        무드: ['캐주얼', '스트릿', '미니멀', '빈티지', '레이지', '젠더리스', '긱시크', '클래식', '유니크']
                    }}
                    categories={['전체', '슬릭컷', '리프컷', '댄디컷', '아이비리그컷', '세미리프컷', '버즈컷', '크롭컷', '소프트드롭컷', '울프컷', '시스루댄디컷', '투블럭컷', '드롭컷', '샤기컷', '리젠트컷', '가일컷', '언더컷', '텍스쳐컷', '생머리', "가르마컷"]}
                />,
            },
            {
                path: "search-dye-detail",
                element: <SearchDetail
                    title={"염색"}
                    filterData={{
                        기장: ['스킨헤드', '짧은 머리', '눈썹 위', '눈 주변', '거지존', '장발'],
                        무드: ['캐주얼', '스트릿', '미니멀', '빈티지', '레이지', '젠더리스', '긱시크', '클래식', '유니크']
                    }}
                    categories={['전체', '레드', '블루', '옐로우', '브라운', '핑크', '그린', '자연모', '기타']}
                />,
            },
            {
                path: "search-perm-detail",
                element: <SearchDetail
                    title={"파마"}
                    filterData={{
                        기장: ['스킨헤드', '짧은 머리', '눈썹 위', '눈 주변', '거지존', '장발'],
                        무드: ['캐주얼', '스트릿', '미니멀', '빈티지', '레이지', '젠더리스', '긱시크', '클래식', '유니크']
                    }}
                    categories={['전체', '프링펌', '다운펌', '히피펌', '가르마펌', '애즈펌', '리프펌', '시스루펌', '베이비펌', '생머리', '빈티지펌', '너드펌', '이지펌']}
                />,
            },
            {
                path: 'login',
                element: <Login />,
            },
            {
                path: 'nickname',
                element: <NickName />,
            },
            {
                path: 'upload',
                element: <Upload />,
            },
            {
                path: 'bookmark',
                element: <Bookmark />,
            },
        ],
    },
]);

export default CustomRouter;
