import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { BottomBar } from '../components/BottomBar';
import { MainTopBar } from '../components/MainTopBar';
import { useLocation, useNavigate } from 'react-router-dom';
import { Toast } from '@components/Toast';
import Masonry from 'react-masonry-css';
import Lottie from 'lottie-react';
import scissor from '../shared/icons/scissors.json';
import '../shared/style/mainAnimation.css';
import { InfiniteScroll } from '@shared/utils/InfiniteScroll';
import {
    LoginReferer,
    Referer,
    useLoginTracking,
    usePrevTrackingNavigate,
} from '@shared/hooks/usePrevTracking';
import { ampli } from '../ampli';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../redux/hooks';
import {
    increaseDepth,
    setHomeOrigin,
    setInitialDepth,
} from '../redux/slices/depth';
import {
    Feed,
    FeedPage,
    HomeFeedPageState,
    addFeedPage,
    addPostsToCurrentFeed,
    stashCurrentFeedToPastFeeds,
    clearFeedPages,
} from '../redux/slices/feed';
import { fetchByKeywordAndPageNumber } from './main.api';
import { UnknownAction } from '@reduxjs/toolkit';
import { PostItem } from '../components/PostItem';
import ImageSkeleton from '@components/ImageSkeleton';
import { setForward } from '../redux/slices/forward';
import { Tooltip } from '@shared/Tooltip';
import { LegoSurpriseImg } from '@shared/imges/LegoSurpriseImg';
import { MainTapBar } from '@components/MainTapBar';
import { RecommendDetailTapBar } from '@components/RecommendDetailTapBar';

export const Main = () => {
    const dispatch = useDispatch();
    const depth = useAppSelector((state) => state.depth.depth);
    const origin = useAppSelector((state) => state.depth.origin);
    const feedPage = useAppSelector(
        (state) => state.feed.currentFeedPage,
    ) as HomeFeedPageState;
    const tooltipText = ("누르면 유사스타일을 볼 수 있어요!");

    const [loading, setLoading] = useState<boolean>(false);
    const [hasMore, setHasMore] = useState<boolean>(true);

    const [showToast, setShowToast] = useState<boolean>(false);
    const [tooltipClosed, setTooltipClosed] = useState(false);

    const [toastMessage, setToastMessage] = useState<string>('');
    const [selectedKeyword, setSelectedKeyword] = useState<string>(() => {
        return localStorage.getItem('selectedKeyword') || '2024 남자머리';
    });

    const [showTooltip, setShowTooltip] = useState(false);

    const navigate = useNavigate();
    const location = useLocation();
    const { refNavigate } = usePrevTrackingNavigate();
    const { logLoginOrigin } = useLoginTracking();

    const handleCloseTooltip = (e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        setShowTooltip(false);
        setTooltipClosed(true);
    };


    useEffect(() => {
        dispatch(setInitialDepth());
        dispatch(setHomeOrigin());
        initializeFeed();
        logLoginOrigin(LoginReferer.ICON);
    }, []);

    useEffect(() => {
        if (!loading && feedPage && feedPage.feed && !tooltipClosed) {
            setShowTooltip(true);
        } else {
            setShowTooltip(false);
        }
    }, [loading, feedPage, tooltipClosed]);

    const initializeFeed = async () => {
        if (feedPage && feedPage.pageType === FeedPage.HOME) {
            return;
        } else {
            dispatch(clearFeedPages());
            setLoading(true);

            const { hasMore, feedId, posts, page } =
                await fetchByKeywordAndPageNumber(selectedKeyword, 1);
            let action: UnknownAction;

            if (!hasMore) {
                setHasMore(false);
                action = addFeedPage({
                    pageType: FeedPage.HOME,
                    loading: false,
                    feed: null,
                    keyword: selectedKeyword,
                } as HomeFeedPageState);
            } else {
                setHasMore(true);
                action = addFeedPage({
                    pageType: FeedPage.HOME,
                    loading: false,
                    feed: {
                        id: feedId,
                        posts: posts,
                        lastPage: page,
                    },
                    keyword: selectedKeyword,
                } as HomeFeedPageState);
            }

            dispatch(action);
            setLoading(false);
        }
    };

    const loadMore = useCallback(async () => {
        if (!loading && hasMore && feedPage && feedPage.feed) {
            setLoading(true);

            const { hasMore, feedId, posts, page } =
                await fetchByKeywordAndPageNumber(
                    selectedKeyword,
                    feedPage.feed.lastPage + 1,
                    feedPage.feed.id,
                );
            let action: UnknownAction;

            if (!hasMore) {
                setHasMore(false);
                action = addPostsToCurrentFeed({
                    id: feedId,
                    posts: [],
                    lastPage: page,
                } as Feed);
            } else {
                setHasMore(true);
                action = addPostsToCurrentFeed({
                    id: feedId,
                    posts: posts,
                    lastPage: page,
                } as Feed);
            }

            dispatch(action);
            setLoading(false);
        }
    }, [loading, hasMore, feedPage, selectedKeyword]);

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const toast = params.get('toast');
        if (toast) {
            setToastMessage(toast);
            setShowToast(true);
            navigate('/', { replace: true });
        }
    }, [location, navigate]);

    const handleCloseToast = () => {
        setShowToast(false);
    };

    const breakpointColumnsObj = useMemo(
        () => ({
            default: 2,
            1100: 2,
            700: 2,
            500: 2,
        }),
        [],
    );

    const handleImgClick = (postId: string) => {
        const clickedPost = feedPage?.feed?.posts.find(
            (post) => post.id === postId,
        );
        ampli.포스트자세히보기썸네일클릭({
            depth: depth,
            origin: origin,
            postId: postId,
            tags: clickedPost?.tags,
        });

        dispatch(setForward());
        dispatch(stashCurrentFeedToPastFeeds());
        dispatch(increaseDepth());
        refNavigate(Referer.HOME)(`/detail/${postId}`, {
            state: {
                isForward: true,
            },
        });
    };

    const handleRecommendChange = async (data: string) => {
        ampli.추천검색어선택({
            keyword: data,
        });

        setSelectedKeyword(data);
        localStorage.setItem('selectedKeyword', data);
        dispatch(clearFeedPages());
        setLoading(true);

        const { hasMore, feedId, posts, page } =
            await fetchByKeywordAndPageNumber(data, 1);
        let action: UnknownAction;

        if (!hasMore) {
            setHasMore(false);
            action = addFeedPage({
                pageType: FeedPage.HOME,
                loading: false,
                feed: null,
                keyword: data,
            } as HomeFeedPageState);
        } else {
            setHasMore(true);
            action = addFeedPage({
                pageType: FeedPage.HOME,
                loading: false,
                feed: {
                    id: feedId,
                    posts: posts,
                    lastPage: page,
                },
                keyword: data,
            } as HomeFeedPageState);
        }

        dispatch(action);
        setLoading(false);
    };

    const handleRefresh = () => {
        ampli.추천검색어리로드버튼클릭();
        setLoading(true);
        initializeFeed();
    };

    return (
        <>
            <MainTopBar
                selectedKeyword={selectedKeyword}
                onChange={handleRecommendChange}
                onRefresh={handleRefresh}
                isLoading={loading}
            />

            {/* 추천탭 */}

            {/* <MainTapBar
                selectedKeyword={selectedKeyword}
                onChange={handleRecommendChange}
                onRefresh={handleRefresh}
                isLoading={loading}
            /> */}

            {/* 맞춤탭 */}

            {/* <RecommendDetailTapBar
                onChange={handleRecommendChange} isLoading={loading} /> */}

            <div className={'w-full px-2 pb-[88px] '}>
                <div className="pt-[120px] relative ">

                    {showTooltip && (
                        <div className="absolute left-[30%] top-[135px]">
                            <Tooltip
                                text={tooltipText}
                                onClose={handleCloseTooltip}
                                isVisible={showTooltip} />
                        </div>
                    )}
                    <InfiniteScroll
                        loadMore={loadMore}
                        hasMore={hasMore}
                        isLoading={loading}
                    >
                        <Masonry
                            breakpointCols={breakpointColumnsObj}
                            className="my-masonry-grid"
                            columnClassName="my-masonry-grid_column"
                        >

                            {feedPage && feedPage.feed
                                ? feedPage.feed.posts.map((d, index) => (
                                    <PostItem
                                        key={`${d.id}-${index}`}
                                        postId={d.id}
                                        imageUrl={d.imageUrl}
                                        tags={d.tags}
                                        uploaderId={d.uploaderId}
                                        onClick={handleImgClick}
                                    />
                                ))
                                : null}
                        </Masonry>
                    </InfiniteScroll>
                </div>
                {loading ? (
                    <div className="col-span-full w-full">
                        <div className="flex justify-center mb-[20px]">
                            <div className="w-12 h-12">
                                <Lottie
                                    animationData={scissor}
                                    loop={true}
                                    autoplay={true}
                                />
                            </div>
                        </div>
                        <div className="flex flex-wrap justify-center gap-2 ">
                            {Array.from({ length: 8 }).map((_, index) => (
                                <ImageSkeleton
                                    key={`skeleton-${index}`}
                                    showScissors={false}
                                    className={
                                        !feedPage
                                            ? 'w-[calc(46vw-8px)] sm:w-[200px] h-[200px] sm:h-[300px] aspect-[3/4] rounded-lg'
                                            : ''
                                    }
                                />
                            ))}
                        </div>
                    </div>
                ) : (
                    //맞춤 탭 빈값시 렌더링
                    <div className="flex-grow">
                        <div className="flex flex-col justify-center items-center h-[85%] text-center">
                            <LegoSurpriseImg />
                            <p className="text-S_Dye_Black font-semibold mt-[31px]">
                                로그인 하고<br />맞춤형 피드를 확인해 보세요
                            </p>
                            <button
                                onClick={() => navigate("/login")}
                                className="w-[328px] py-2 text-center rounded-[4px] bg-S_Dye_Black mt-6"
                            >
                                <p className="place-content-center text-lg font-semibold text-S_White">
                                    로그인 하러가기
                                </p>
                            </button>
                        </div>
                    </div>
                )}
            </div>
            <BottomBar activePage={'/'} />
            <div className="sticky w-full left-0 right-0 bottom-24 z-50">
                {showToast && (
                    <Toast
                        message={toastMessage}
                        duration={3000}
                        onClose={handleCloseToast}
                    />
                )}
            </div>
        </>
    );
};