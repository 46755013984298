import { useEffect, useRef, useState } from 'react';

export const useImageZoom = () => {
    const containerRef = useRef(null);
    const imgRef = useRef(null);
    const [isZoomed, setIsZoomed] = useState(false);
    const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

    const toggleZoom = () => {
        setIsZoomed((prev) => !prev);
    };

    const handleMouseMove = (e) => {
        if (!containerRef.current) return;
        const rect = imgRef.current.getBoundingClientRect();
        const x = ((e.clientX - rect.left) / rect.width) * 100;
        const y = ((e.clientY - rect.top) / rect.height) * 100;
        setMousePosition({ x, y });
    };

    useEffect(() => {
        const imgElement = imgRef.current;
        const containerElement = containerRef.current;
        if (!imgElement || !containerElement) return;

        if (isZoomed) {
            containerElement.style.transformOrigin = 'hidden';
            imgElement.style.transformOrigin = `${mousePosition.x}% ${mousePosition.y}%`;
            imgElement.style.transform = 'scale(3)';
            imgElement.style.transition = 'transform 0.3s ease-in-out';
        } else {
            containerElement.style.overflow = '';
            imgElement.style.transform = 'scale(1)';
        }

    }, [isZoomed, mousePosition]);

    return { containerRef, imgRef, toggleZoom, handleMouseMove, isZoomed };
};
