import React, { useState } from 'react';
import { ampli } from '../ampli';
import { SearchFeedPageState } from '../redux/slices/feed';
import { useAppSelector } from '../redux/hooks';

interface ILinkCopyBottomSheet {
    isOpen: boolean;
    onClose: () => void;
    onClick: () => void;
}

export const LinkCopyBottomSheet = ({
    isOpen,
    onClose,
    onClick
}: ILinkCopyBottomSheet) => {
    if (!isOpen) return null;

    const handleCopyAndClose = () => {
        onClick();  // 링크 복사
        onClose();  // 시트 닫기
    };


    return (
        <>
            <div
                className="fixed inset-0 bg-black bg-opacity-50 z-40"
                onClick={onClose}
            ></div>
            <div className="fixed w-full  max-w-[500px] bottom-0 left-1/2 transform -translate-x-1/2 top-[63%] bg-S_White rounded-t-2xl z-50 px-4 py-9 text-center">
                <p className='font-medium'>손이고는 <span className='text-S_Wave_Orange'>외부 웹에 최적화</span> 되었어요.<br />
                    링크를 복사해서 인스타그램이 아닌<br />
                    다른 웹으로 접속해 보세요! <br /><span className='text-S_Dye_1'>예) 네이버, 구글 크롬, 사파리 ⋯</span></p>
                <button
                    className="w-full py-4 text-center rounded-full bg-S_Dye_Black text-white font-semibold mt-6"
                    onClick={handleCopyAndClose}
                >
                    링크 복사하기
                </button>
            </div>
        </>
    );
};