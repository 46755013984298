import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { DropdownIcon } from '@shared/icons/DropdownIcon';
import { ProfileIcon } from '@shared/icons/ProfileIcon';
import { BottomSheet } from '../components/BottomSheet';
import { RefreshIcon } from '@shared/icons/RefreshIcon';
import { useModal } from '@shared/hooks/useModal.hook';
import { LegoSadImg } from '@shared/imges/LegoSadImg';
import {
    womenModalContainer,
    womenModalLayout,
    womenModalTransiton,
} from '@shared/style/womenModal.styled';
import {
    loginModalContainer,
    loginModalLayout,
    loginModalTransiton,
} from '@shared/style/loginModal.styled';
import { useOutsideClick } from '@shared/hooks/useOutsideClick.hook';
import { useNavigate } from 'react-router-dom';
import { clearAccessTokenCookie, getAccessTokenCookie } from '../api/API';
import { ampli } from '../ampli';
import { scrollToTop } from '@shared/utils/scrollToTop';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { logout } from '../redux/slices/user';

interface IData {
    data: string[];
}

const Data: IData = {
    data: [
        '2024 남자머리',
        '샤기컷 붐은 온다',
        '장발이 하고싶나?',
        '헤어도 빈티지',
        '집돌이 무드',
        '미친 개성',
        '뽀글뽀글',
    ],
};

interface RecommendationsProps {
    keywordList: string[];
    selectedKeyword: string;
    onClick: (keyword: string) => void;
    isLoading: boolean;
}

const Recommendations: React.FC<RecommendationsProps> = ({
    keywordList,
    selectedKeyword,
    onClick,
    isLoading,
}) => {
    const scrollContainerRef = useRef<HTMLUListElement>(null);
    const scrollPositionRef = useRef<number>(0);

    function handleKeywordSelect(keyword: string) {
        onClick(keyword);
        scrollToTop();
    }

    useEffect(() => {
        if (scrollContainerRef.current) {
            scrollPositionRef.current = scrollContainerRef.current.scrollLeft;
        }
    });

    useLayoutEffect(() => {
        if (scrollContainerRef.current && !isLoading) {
            scrollContainerRef.current.scrollLeft = scrollPositionRef.current;
        }
    }, [isLoading]);

    useEffect(() => {
        if (scrollContainerRef.current && !isLoading) {
            const selectedElement = scrollContainerRef.current.querySelector(
                `[data-keyword="${selectedKeyword}"]`,
            ) as HTMLElement;
            if (selectedElement) {
                const container = scrollContainerRef.current;
                const containerRect = container.getBoundingClientRect();
                const elementRect = selectedElement.getBoundingClientRect();

                const isFullyVisible =
                    elementRect.left >= containerRect.left &&
                    elementRect.right <= containerRect.right;

                if (!isFullyVisible) {
                    const scrollLeft =
                        selectedElement.offsetLeft -
                        container.clientWidth / 2 +
                        selectedElement.offsetWidth / 2;
                    container.scrollTo({
                        left: scrollLeft,
                        behavior: 'smooth',
                    });
                }
            }
        }
    }, [selectedKeyword, isLoading]);

    useEffect(() => {
        const slider = scrollContainerRef.current;
        if (!slider) return;

        let isDown = false;
        let startX: number;
        let scrollLeft: number;

        const handleDragStart = (e: MouseEvent | TouchEvent) => {
            isDown = true;
            slider.classList.add('active');
            if (e instanceof MouseEvent) {
                startX = e.pageX - slider.offsetLeft;
            } else {
                startX = e.touches[0].pageX - slider.offsetLeft;
            }
            scrollLeft = slider.scrollLeft;
        };

        const handleDragEnd = () => {
            isDown = false;
            slider.classList.remove('active');
        };

        const handleDragMove = (e: MouseEvent | TouchEvent) => {
            if (!isDown) return;
            e.preventDefault();
            let x: number;
            if (e instanceof MouseEvent) {
                x = e.pageX - slider.offsetLeft;
            } else {
                x = e.touches[0].pageX - slider.offsetLeft;
            }
            const walk = (x - startX) * 1.5;
            slider.scrollLeft = scrollLeft - walk;
        };

        slider.addEventListener('mousedown', handleDragStart);
        slider.addEventListener('touchstart', handleDragStart);

        slider.addEventListener('mouseleave', handleDragEnd);
        slider.addEventListener('mouseup', handleDragEnd);
        slider.addEventListener('touchend', handleDragEnd);

        slider.addEventListener('mousemove', handleDragMove);
        slider.addEventListener('touchmove', handleDragMove);

        return () => {
            slider.removeEventListener('mousedown', handleDragStart);
            slider.removeEventListener('touchstart', handleDragStart);
            slider.removeEventListener('mouseleave', handleDragEnd);
            slider.removeEventListener('mouseup', handleDragEnd);
            slider.removeEventListener('touchend', handleDragEnd);
            slider.removeEventListener('mousemove', handleDragMove);
            slider.removeEventListener('touchmove', handleDragMove);
        };
    }, []);

    return (
        <div className="flex-1 overflow-hidden">
            <ul
                ref={scrollContainerRef}
                className="flex items-center flex-nowrap whitespace-nowrap gap-2 overflow-x-auto scrollbar-hide cursor-grab active:cursor-grabbing"
                style={{ overscrollBehaviorX: 'contain', userSelect: 'none' }}
            >
                {keywordList.map((d, index) => (
                    <li
                        key={index}
                        data-keyword={d}
                        onClick={() => handleKeywordSelect(d)}
                        className={`flex items-center justify-center flex-shrink-0 py-2 px-3 font-medium rounded-[4px]
                            ${
                                selectedKeyword === d
                                    ? 'border-[1.2px] border-S_Wave_Orange text-S_Wave_Orange px-[12px]'
                                    : 'border-[0.5px] border-S_Dye_1 text-S_Dye_1 px-[12.5px]'
                            }
                        `}
                    >
                        <span>{d}</span>
                    </li>
                ))}
            </ul>
        </div>
    );
};

interface MainTopBarProps {
    onChange: (data: string) => void;
    selectedKeyword: string;
    onRefresh: () => void;
    isLoading: boolean;
}

export const MainTopBar: React.FC<MainTopBarProps> = ({
    onChange,
    onRefresh,
    isLoading,
}) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const sonigoId = useAppSelector((state) => state.user.sonigoId);

    const [isBottomSheetOpen, setIsBottomSheetOpen] = useState(false);
    const [selectedGender, setSelectedGender] = useState('남자');
    const [showZIndex, setShowZIndex] = useState(false);
    const [isClosing, setIsClosing] = useState(false);
    const access = getAccessTokenCookie();

    const [selectedKeyword, setSelectedKeyword] = useState<string>(() => {
        return localStorage.getItem('selectedKeyword') || '2024 남자머리';
    });

    const handleOpenBottomSheet = () => {
        setIsBottomSheetOpen(true);
        setShowZIndex(true);
    };
    const handleCloseBottomSheet = () => {
        setIsBottomSheetOpen(false);
        setShowZIndex(false);
    };

    const womenModalRef = useRef<HTMLDivElement>(null);
    const loginModalRef = useRef<HTMLDivElement>(null);
    const {
        isModalOpen: womenModalOpen,
        openModal: womenOpenModal,
        closeModal: womenCloseModal,
    } = useModal<null>(null);

    const {
        isModalOpen: loginModalOpen,
        openModal: loginOpenModal,
        closeModal: loginCloseModal,
    } = useModal<null>(null);

    useOutsideClick(loginModalRef, loginCloseModal, loginModalOpen);

    useEffect(() => {
        if (selectedGender === '여자') {
            womenOpenModal();
            setSelectedGender('남자');
        }
    }, [selectedGender]);

    useEffect(() => {
        setShowZIndex(isBottomSheetOpen || loginModalOpen || womenModalOpen);
    }, [isBottomSheetOpen, loginModalOpen, womenModalOpen]);

    const handleLoginOpenModal = () => {
        if (access) {
            loginOpenModal();
        } else {
            navigate('/login');
        }
    };

    const handleLogout = () => {
        const url = window.location.href;
        const parts = url.split('/');
        const newUrl = parts.slice(0, 3).join('/');
        ampli.로그아웃();
        dispatch(logout());
        clearAccessTokenCookie();
        loginCloseModal();
        setShowZIndex(false);
        window.location.href = newUrl;
    };
    const handleModalClose = () => {
        setIsClosing(true);
        setTimeout(() => {
            womenCloseModal();
            setIsClosing(false);
        }, 200);
    };

    const handleKeywordChange = (keyword: string) => {
        setSelectedKeyword(keyword);
        localStorage.setItem('selectedKeyword', keyword);
        onChange(keyword);
    };

    return (
        <div
            className={`fixed top-0 left-1/2 transform -translate-x-1/2 max-w-[500px] w-full ${showZIndex ? 'z-50 h-full' : 'z-20'}`}
        >
            <div className="flex flex-col bg-S_White w-full p-4">
                <div className="flex items-center">
                    <button
                        className="flex items-center text-S_Dye_Black font-semibold text-lg gap-3"
                        onClick={handleOpenBottomSheet}
                    >
                        <p>남자</p>
                        <DropdownIcon />
                    </button>
                    <div className="flex-grow"></div>
                    <button onClick={handleLoginOpenModal}>
                        <ProfileIcon />
                    </button>
                </div>
                <div className="flex w-full mt-[17px]">
                    {/*<button className="flex-shrink-0 mr-2" onClick={onRefresh}>*/}
                    {/*    <RefreshIcon />*/}
                    {/*</button>*/}
                    <Recommendations
                        keywordList={Data.data}
                        selectedKeyword={selectedKeyword}
                        onClick={handleKeywordChange}
                        isLoading={isLoading}
                    />
                </div>
            </div>
            {showZIndex && (
                <div className="fixed inset-0 z-40">
                    <div className={`fixed inset-0`}>
                        <BottomSheet
                            isOpen={isBottomSheetOpen}
                            onClose={handleCloseBottomSheet}
                            setSelectedGender={setSelectedGender}
                        />
                    </div>
                    <div
                        className={`${womenModalOpen && !isClosing ? 'opacity-100 ' : 'opacity-0'} ${womenModalTransiton.class}`}
                    >
                        {womenModalOpen && (
                            <div className={womenModalLayout.class}>
                                <div
                                    ref={womenModalRef}
                                    className={womenModalContainer.class}
                                >
                                    <LegoSadImg />
                                    <p className="mt-8 font-medium text-[#000000]">
                                        여자 머리는 준비 중입니다...
                                    </p>
                                    <p className="mb-[35px] font-medium text-[#000000]">
                                        죄송합니다...
                                    </p>
                                    <button
                                        onClick={handleModalClose}
                                        className="w-[328px] h-[41px] text-center rounded-[4px] bg-S_Dye_Black"
                                    >
                                        <p className="place-content-center text-lg font-semibold text-S_White">
                                            확인
                                        </p>
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                    {loginModalOpen && (
                        <div
                            className={`${loginModalOpen && !isClosing ? 'opacity-100 ' : 'opacity-0'} ${loginModalTransiton.class}`}
                        >
                            <div className={loginModalLayout.class}>
                                <div
                                    ref={loginModalRef}
                                    className={loginModalContainer.class}
                                >
                                    <ProfileIcon width={40} height={40} />
                                    {sonigoId && access && (
                                        <p className="text-S_Dye_Black font-medium text-sm mt-4">
                                            {(sonigoId as string) || undefined}
                                        </p>
                                    )}
                                    <div className="flex grow"></div>
                                    <button
                                        className="text-S_Wave_Orange font-semibold text-sm mb-[42px] underline"
                                        onClick={handleLogout}
                                    >
                                        로그아웃
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};
