import { createSlice } from '@reduxjs/toolkit';

export const refreshSlice = createSlice({
    name: 'REFRESH',
    initialState: {
        refresh: false,
    },
    reducers: {
        setRefresh: (state) => {
            state.refresh = true;
        },
        unsetRefresh: (state) => {
            state.refresh = false;
        },
    },
});

export const { setRefresh, unsetRefresh } = refreshSlice.actions;
export default refreshSlice.reducer;
