import React, { useRef, useCallback, useEffect } from 'react';

interface InfiniteScrollProps {
    loadMore: () => void;
    hasMore: boolean;
    isLoading: boolean;
    children: React.ReactNode;
}

export const InfiniteScroll: React.FC<InfiniteScrollProps> = ({
    loadMore,
    hasMore,
    isLoading,
    children
}) => {
    const observer = useRef<IntersectionObserver | null>(null);
    const targetRef = useRef<HTMLDivElement | null>(null); // Ref to the target element (e.g., a "load more" div)

    const observeTarget = useCallback(() => {
        if (isLoading || !hasMore) return; // Prevent observing if loading or no more content

        if (observer.current) observer.current.disconnect(); // Disconnect any existing observer

        observer.current = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting) {
                loadMore(); // Trigger loadMore when the target comes into view
            }
        });

        if (targetRef.current) observer.current.observe(targetRef.current); // Start observing the target element
    }, [isLoading, hasMore, loadMore]);

    // Set up the observer when the component is mounted or when the relevant states change
    useEffect(() => {
        observeTarget();

        return () => {
            if (observer.current) observer.current.disconnect(); // Clean up observer when component unmounts
        };
    }, [observeTarget]);

    return (
        <>
            {children}
            {/* This is the target div that will trigger the IntersectionObserver */}
            {hasMore && <div ref={targetRef} className="load-more-target" />}
            {/* Empty div with height 20 */}
            <div style={{ height: '10px' }} />
        </>
    );
};

