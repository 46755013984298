import React from 'react';
import { BottomBar } from '@components/BottomBar';
import { useNavigate } from 'react-router-dom';
import { ampli } from '../ampli';

export const Search = () => {
    const items = [
        {
            id: 1,
            category: 'cut',
            image: '/search_cut.png',
            navigateTo: '/search-cut-detail',
        },
        {
            id: 2,
            category: 'color',
            image: '/search_dye.png',
            navigateTo: '/search-dye-detail',
        },
        {
            id: 3,
            category: 'perm',
            image: '/search_perm.png',
            navigateTo: '/search-perm-detail',
        },
    ];

    const navigate = useNavigate();

    return (
        <div className="flex flex-col w-full min-h-screen px-2">
            <h1 className="text-2xl font-bold py-[14px] pl-2 bg-white">탐색</h1>
            <div className="flex-grow overflow-auto ">
                <div className="grid grid-cols-2 gap-3 mt-3">
                    {items.map((item) => (
                        <div key={item.id} className="aspect-w-1 aspect-h-1">
                            <img
                                src={item.image}
                                alt=""
                                className="object-cover rounded-lg w-full h-full cursor-pointer"
                                onClick={() => {
                                    ampli.검색대분류버튼클릭({
                                        searchCategory: item.category,
                                    });
                                    navigate(item.navigateTo);
                                }}
                            />
                        </div>
                    ))}
                </div>
            </div>
                <BottomBar activePage={'/search'} />
        </div>
    );
};
