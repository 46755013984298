import React, { useEffect, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';

interface ToastProps {
    message: React.ReactNode;
    duration?: number;
    onClose: () => void;
}

export const Toast: React.FC<ToastProps> = ({
    message,
    duration = 3000,
    onClose,
}) => {
    const [isVisible, setIsVisible] = useState<boolean>(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsVisible(false);
            setTimeout(onClose, 1000);
        }, duration);

        return () => clearTimeout(timer);
    }, [duration, onClose]);

    return (
        <AnimatePresence>
            {isVisible && (
                <motion.div
                    className="flex justify-center items-center text-center"
                    key={location.pathname}
                    initial={{ opacity: 0, y: 100 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.6, ease: 'easeInOut' }}
                    exit={{ opacity: 0, y: 100 }}
                >
                    <div className="w-[90%] px-4 py-2 bg-S_Dye_5 rounded-[4px] max-w-[500px]">
                        <p className="text-S_White font-semibold">{message}</p>
                    </div>
                </motion.div>
            )}
        </AnimatePresence>
    );
};
