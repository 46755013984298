import React from 'react';
import { HomeBtn } from '@shared/buttons/HomeBtn';
import { SearchBtn } from '@shared/buttons/SearchBtn';
import { UploadBtn } from '@shared/buttons/UploadBtn';
import { useNavigate } from 'react-router-dom';
import { ampli } from '../ampli';
import { BookMarkBtn } from '@shared/buttons/BottomBar/BookmarkBtn';
import { buttonExperimentFlagKey, experiment } from '@app/App';
import { LikeBtn } from '@shared/buttons/BottomBar/LikeBtn';
import { ContainBtn } from '@shared/buttons/BottomBar/ContainBtn';

interface BottomBarProps {
    activePage?: string;
    onClick?: () => void;
}

export const BottomBar: React.FC<BottomBarProps> = ({ activePage }) => {
    const navigate = useNavigate();

    const varient = experiment.variant(buttonExperimentFlagKey);
    console.log('varient', varient.value);

    return (
        <div className="fixed flex justify-around items-center bottom-0 left-0 right-0 max-w-[500px] mx-auto bg-S_White py-3 border-t border-S_Dye_1 pb-[30px]">
            <HomeBtn
                isActive={activePage === '/'}
                onClick={() => {
                    ampli.홈탭();
                    navigate('/');
                }}
            />
            <SearchBtn
                isActive={
                    activePage === '/search' ||
                    activePage === '/research-detail'
                }
                onClick={() => {
                    ampli.검색탭();
                    navigate('/search');
                }}
            />
            <UploadBtn
                onClick={() => {
                    ampli.업로드탭();
                    navigate('/upload');
                }}
            />
            {
                varient.value === 'save_type' ? (
                    <BookMarkBtn
                        isActive={activePage === 'bookmark'}
                        onClick={() => {
                            ampli.저장탭();
                            navigate('/bookmark');
                        }}
                    />
                ) : varient.value === 'like_type' ? (
                    <LikeBtn
                        isActive={activePage === 'bookmark'}
                        onClick={() => {
                            ampli.저장탭();
                            navigate('/bookmark');
                        }}
                    />
                ) : varient.value === 'collect_type' ? (
                    <ContainBtn
                        isActive={activePage === 'bookmark'}
                        onClick={() => {
                            ampli.저장탭();
                            navigate('/bookmark');
                        }}
                    />
                ) : (
                    <BookMarkBtn
                        isActive={activePage === 'bookmark'}
                        onClick={() => {
                            ampli.저장탭();
                            navigate('/bookmark');
                        }}
                    />
                )
            }
        </div>
    );
};
