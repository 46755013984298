import React, { useState } from 'react';
import { BookmarkIcon } from '@shared/icons/BookmarkIcon';
import { Tooltip } from '@shared/Tooltip';

interface BookmarkReqBtnProps {
    text: string;
    isBookmarked: boolean;
}

export const BookmarkReqBtn: React.FC<BookmarkReqBtnProps> = ({
    text,
    isBookmarked,
}) => {
    const tooltipText = '스타일을 저장해 맞춤형 피드를 만들어보세요.';
    const [showTooltip, setShowTooltip] = useState(true);

    const handleCloseTooltip = (e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        setShowTooltip(false);
    };

    return (
        <>
            {showTooltip && (
                <div className="absolute top-[-28%] left-[0%]">
                    <Tooltip
                        text={tooltipText}
                        arrowPosition={30}
                        onClose={handleCloseTooltip}
                        isVisible={showTooltip}
                    />
                </div>
            )}
            <div
                className={`w-full rounded-[4px] flex items-center justify-center py-[11px]
                ${isBookmarked ? 'bg-S_Dye_1' : 'bg-S_Dye_5'}
                `}
            >
                <BookmarkIcon />
                <p className=" ml-[10px] font-medium text-S_White text-sm">
                    {text}
                </p>
            </div>
        </>
    );
};
