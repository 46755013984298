import { combineReducers, configureStore } from '@reduxjs/toolkit';
import persistReducer from 'redux-persist/lib/persistReducer';
import storage from 'redux-persist/lib/storage/session';
import depthReducer from './slices/depth';
import feedReducer from './slices/feed';
import userReducer from './slices/user';
import forwardReducer from './slices/forward';
import refreshReducer from './slices/refresh';

export const reducers = combineReducers({
    depth: depthReducer,
    feed: feedReducer,
    user: userReducer,
    forward: forwardReducer,
    refresh: refreshReducer,
});

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['feed', 'depth', 'user', 'forward'],
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
});

export type AppStore = typeof store;
export type RootState = ReturnType<AppStore['getState']>;
export type AppDispatch = AppStore['dispatch'];
