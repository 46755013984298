export const categoryTitleTranslator = (title: string): string => {
    switch (title) {
        case "cut":
            return "cut";
        case "color":
            return "color";
        case "perm":
            return "perm";
        case "커트":
            return "cut";
        case "염색":
            return "color";
        case "파마":
            return "perm";
        default:
            return "unknown";
    }
}